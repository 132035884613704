import React, { useState } from "react";
import TrashOrange from "../../../images/trashOrange.svg";
import PlusboxOrange from "../../../images/plusboxOrange.svg";
import MinusboxOrange from "../../../images/MinusOrange.svg";
import foodImg from "../../../images/food.png";
import { Commande } from "../../../models/services/module.order";
import { config } from "../../../config";
import { generateSupplementsString } from "../../../utils/compositionToString";
import QRcodeTest from "../../../images/QRcodeTest.svg";

export default function CartSubElementsDetails({
  command,
  selectedOrder,
}: {
  command?: serviceDetailsOrderModels.DetailCommande;
  selectedOrder?: Commande;
}) {
  const [quantity, setQuantity] = useState(1);
  const [iconProduct, setIconProduct] = useState(TrashOrange);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    setIconProduct(MinusboxOrange);
  };

  const handleDecrement = () => {
    if (quantity === 1) {
      setIconProduct(TrashOrange);
    } else {
      setQuantity((prevQuantity) => prevQuantity - 1);
      setIconProduct(MinusboxOrange);
    }
  };
  return (
    <div className="menuElement animated animatedFadeInUp fadeInUp">
      <div className="menu-header">
        <div className="col-1-header">
          <p className="menu-name">{command?.name}</p>
          <img
            className="menu-img"
            src={
              config.api_baseUrl_Picture + "/" + selectedOrder?.imageRestaurant
            }
            alt=""
          />
        </div>
        <div className="col-2-header">
          <div className="slot-menu">
            <img
              className=""
              src={iconProduct}
              alt=""
              onClick={handleDecrement}
            />
            <p className="quantity">{quantity}</p>
            <img
              className=""
              src={PlusboxOrange}
              alt=""
              onClick={handleIncrement}
            />
          </div>
          <div className="price">
            <p>
              {command?.totalCost}
              <span> €</span>
            </p>
          </div>
        </div>
      </div>
      {command && command?.components?.length > 0 && (
        <div className="menu-content">
          <div className="menu-content-wrapper">
            {command.components?.map((el) => (
              <div className="menu-list">
                <p className="menuItemName">{el.name}</p>
                <div className="menusItemList">
                  <div className="SingleMenuList">
                    <p className="menuSubName">{el.name}</p>
                    <p className="SubitemDescription">
                      {generateSupplementsString(el?.compositions ?? [])}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
