import { Container, Grid } from "@mui/material";
import withNavigateHook from "../../components/WithNavigation";
import CategoryWheel from "../../components/categorieWheel";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  addShopToFavorite,
  categoryShopsChangeShopMode,
  currentCategoryChange,
  getFiltredShops,
  getmyprofile,
  logout,
  updateCartListById,
} from "../../store/actions/actions";
import CardDos from "../../components/Cards/CardDos";
import "./style.scss";
// import noelement from "../../images/imagesfirstpage/noelement.svg";
import NavBar from "../../components/Nbar/Nav-bar";

import Preloader from "../../components/Preloader/Preloader";
import { useLocation } from "react-router-dom";
import { languageData } from "../../i18n";

const Restaurant = (props: any) => {
  const { cartList, shops } = props;
  const [shopsFio, SetShopsFio] = useState<any[]>(props.shops);
  const [buttonState, setButtonState] = useState(true);
  const [RestorentFilter, setRestorentFilter] = useState("");
  const [changeColor, setChangeColor] = useState(true);
  const [changeColor2, setChangeColor2] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const cartListMaper = Object.values(props.savedCategories);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [diningMode, setDiningMode] = useState(props.categoryShopMode);
  const location = useLocation();
  const prevState = location.state;

  // const filteredCartListMaper = cartListMaper.filter((item: any) =>
  //   item.categories.some((category: any) => category.quantity !== 0)
  // );

  // const shiftss = shops.map((shop: any) => {
  //   return shop?.shifts?.filter((ss: any) => ss?.isCurrentShift === true);
  // });

  // const hasUser = props.userInfo !== null && props.userInfo !== undefined;
  // const UserLogIn =
  //   props.sendVerificationCodeResponse !== null &&
  //   props.sendVerificationCodeResponse !== undefined;

  // const token = localStorage.getItem("token");
  // const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (location.pathname === "/restaurants") {
      localStorage.removeItem("resturantId");
    }
  }, []);

  useEffect(() => {
    const data = props.getmyprofile();
  }, []);

  useEffect(() => {
    if (props?.shops) {
      SetShopsFio(props.shops);
    }
  }, [props?.shops]);

  useEffect(() => {
    // Effect to run when shopsFio changes
    console.log("Updated shopsFio:", shopsFio);
  }, [shopsFio]);

  useEffect(() => {
    //props.changeCurrentCategory([]);
  }, []);

  localStorage.setItem("stateChanged", String(changeColor));

  useEffect(() => {
    let body: serviceShopModels.getFiltredShopBody = {
      diningMode: diningMode,
      categories: props.currentCategory,
      adresse: {
        latitude: props.location?.lat,
        longitude: props.location?.lng,
      },
    };

    props.getFiltredShops({ ...body });
  }, [
    props.shopMode,
    props.currentCategory,
    props.location?.shownAddress,
    diningMode,
  ]);

  const handleClicke = (id: any) => {
    props.navigate(`/restaurant/${id}`);
  };
  useEffect(() => {
    const handleScroll = () => {
      let offsetY = window.pageYOffset;
      let moving = window.pageYOffset;

      if (offsetY > 130) {
        setVisible(position > moving);
        setPosition(moving);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  useEffect(() => {
    if (diningMode == 0 || diningMode == 1) {
      props.changeCategoryShopMode(diningMode);
    }
  }, [diningMode]);
  const ImageBaseUrl = "http://40.127.178.155:93/uploads";
  const cls = visible ? "visible" : "hidden";
  return (
    <section>
      <NavBar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />

      <Grid
        item
        xs={12}
        md={8}
        lg={8}
        xl={8}
        container
        justifyContent="center"
        alignItems="center"
      ></Grid>

      <Container className="restaurant-container">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ margin: "auto", marginBottom: "5rem" }}
        >
          <div className="listing-restaurant-header">
            <div className={`${cls} sticky-filter`}>
              <section className="filter-cat-by-place delivery-type">
                <p
                  className={diningMode ? "" : "active-tab"}
                  onClick={() => setDiningMode(0)}
                >
                  {languageData.fr.DeliveryTabSwitcher.str_aemporter}
                </p>
                <p
                  className={diningMode ? "active-tab" : ""}
                  onClick={() => setDiningMode(1)}
                >
                  {languageData.fr.DeliveryTabSwitcher.str_surplace}
                </p>
              </section>

              {/* Category Wheel */}
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={16} sx={{}}>
            
          </Grid> */}
              <CategoryWheel />
            </div>
          </div>
          {/* Cards */}
          <Grid item xs={12} className="listing-restaurants-container">
            <Grid
              className="listing-restaurants"
              container
              justifyContent="center"
              spacing={2}
              columnGap={"10px"}
              rowGap={"5px"}
            >
              {!props.homeLoading ? (
                <Fragment>
                  {shopsFio
                    ?.filter((shop: any) =>
                      shop.nom
                        .toLowerCase()
                        .includes(RestorentFilter.toLowerCase())
                    )
                    ?.map((shop: any, i: number) => (
                      <Grid
                        item
                        key={i}
                        className="row card-restaurant"
                        sx={{ marginBottom: 2, marginLeft: "-10px" }}
                      >
                        {" "}
                        <CardDos
                          shopsFio={shopsFio}
                          shop={shop}
                          id={shop.id}
                          onFavoriteChange={(idRestaurant: any, value: any) =>
                            props.addRestaurantToFavorite(idRestaurant, value)
                          }
                          nom={shop.nom}
                          imageUrl={shop.imageUrl}
                          isFavorite={shop.isFavorite}
                          isOpen={shop.isOpen}
                          distance={shop.distance}
                          tempsPrep={shop.tempsPrep}
                          note={shop.note}
                          shifts={shop.shifts}
                        />
                      </Grid>
                    ))}
                  {shopsFio?.filter((shop: any) =>
                    shop.nom
                      .toLowerCase()
                      .includes(RestorentFilter.toLowerCase())
                  ).length === 0 && (
                    <div className="no-results">
                      <img src="" />{" "}
                      <p
                        style={{
                          fontSize: "28px",
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          color: "#1D2939",
                        }}
                      >
                        Aucun restaurant trouvée
                      </p>
                    </div>
                  )}
                </Fragment>
              ) : (
                // <Preloader />
                <div></div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    homeLoading: state.shop.homeLoading,
    shops: state.shop.shops,
    shopMode: state.shop.mode,
    currentCategory: state.category.currentCategory,
    restaurantNameFilter: state.shop.restaurantNameFilter,
    cartList: state.order.cartList,
    cartListById: state.order.cartListById,
    savedCategories: state.cart.savedCategories,
    userInfo: state.auth.userInfo,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getFiltredShops: (body: serviceShopModels.getFiltredShopBody) =>
      dispatch(getFiltredShops(body)),
    getmyprofile: () => dispatch(getmyprofile()),
    addRestaurantToFavorite: (idRestaurant: number, value: boolean) =>
      dispatch(addShopToFavorite(idRestaurant, value)),
    cartRestById: (restaurantById: any) =>
      dispatch(updateCartListById(restaurantById)),
    logout: () => dispatch(logout()),
    changeCurrentCategory: (value: number) =>
      dispatch(currentCategoryChange(value)),
    changeCategoryShopMode: (value: number) =>
      dispatch(categoryShopsChangeShopMode(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(Restaurant));
