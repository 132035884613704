import React, { useState, useRef, useEffect } from "react";
import logo from "../../../../images/logo-black.svg";
import Eye from "../../../../images/eye.svg";
import AppelLogo from "../../../../images/appel-Icon.svg";
import GoogleLogo from "../../../../images/google-icon.svg";
import CloseIcon from "../../../../images/CloseIcon_btn.svg";

import "./style.scss";
import { Link } from "react-router-dom";

export default function AuthLoginModal() {
  const [password, setPassword] = useState("");
  const [ShowPw, SetPassword] = useState(false);
  const [showAuth, setShowAuth] = useState(true);
  const [hasEnteredEmail, setHasEnteredEmail] = useState(false);

  const userEmailRef = useRef<HTMLInputElement | null>(null);

  const handleContinue = () => {
    const userEmail = userEmailRef.current?.value || "";
    if (userEmail.trim() !== "") {
      setHasEnteredEmail(true);
    }
  };

  const handleCloseModal = () => {
    document.querySelector(".AuthModal")?.classList.remove("ShowAuth");

    setShowAuth(false);
  };

  return (
    <div className="AuthLoginModal">
      <div className="closeBtn" onClick={handleCloseModal}>
        <img className="" src={CloseIcon} alt="CloseIcon" />
      </div>
      <div
        className={`wrapper-auth UserEmail ${hasEnteredEmail ? "hideblockElement" : ""}`}
      >
        <div className="header-auth">
          <img className="logo-head" src={logo} alt="" />
          <p className="title-head">Inscription ou connexion</p>
          <p className="description-head">
            Saisissez votre numéro de téléphone ou votre <br /> adresse mail
            pour vous connecter
          </p>
        </div>
        <div className="fields-auth">
          <div className="email-field">
            <label htmlFor="email">Téléphone ou votre e-mail</label>
            <input
              type="email"
              name="email"
              id="user-email"
              ref={userEmailRef}
            />
          </div>
          <div className="seperator">
            <div></div>
            <p>ou</p>
            <div></div>
          </div>
          <div className="LoginWith">
            <div className="apple-auth">
              <img src={AppelLogo} alt="" />
              <p>Continuer avec Apple</p>
            </div>
            <div className="google-auth">
              <img src={GoogleLogo} alt="" />
              <p>Continuer avec Google</p>
            </div>
          </div>
        </div>
        <div className="footer-auth">
          <button onClick={handleContinue}>Continue</button>
        </div>
      </div>
      <div
        className={`wrapper-auth UserPassword ${hasEnteredEmail ? "" : "hideblockElement"}`}
      >
        <div className="header-auth">
          <img className="logo-head" src={logo} alt="" />
          <p className="title-head">Inscription ou connexion</p>
          <p className="description-head">
            Saisissez votre mot de passe <br />
            pour vous connecter
          </p>
        </div>
        <div className="field-password">
          <div className="password-field">
            <label htmlFor="password"></label>
            <div className="col-field">
              <input
                type={`${ShowPw ? "text" : "password"}`}
                name="password"
                id=""
                placeholder="••••••••"
              />
              <img
                onClick={() => SetPassword(!ShowPw)}
                className="show-pw"
                src={Eye}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="footer-auth">
          <Link style={{width: "100%"}} to={"/paiement"}>
            <button>Se connecter</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
