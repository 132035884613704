import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import withNavigateHook from "../WithNavigation";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import {
  editMyProfile,
  editmyprofileFailure,
  editmyprofileSuccess,
  getmyprofile,
  logout,
} from "../../store/actions/actions";
import { languageData } from "../../i18n";
import { validation } from "../../utils/Validation";
import { NavAuth } from "../../apps/authentification/nav";
import NavBar from "../Nbar/Nav-bar";
import LeftArrow from "../../images/arrow-left_grey.svg";
import EditOrangeIcon from "../../images/EditOrangeIcon.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import ValidateIcon from "../../images/ValidateIcon.svg";
import "./index.scss";
function MonProfile(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [Confirmerpassword, setConfirmerpassword] = useState("");
  const [hasErrorInput, sethasErrorInput] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  //error message
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [ShowPwFields, SetShowPwFields] = useState(false);
  const [type, setType] = useState("password");

  const [errorPassword, setErrorPassword] = useState<string>("");
  const [errorOldPassword, setErrorOldPassword] = useState<string>("");
  const [errorConfirmerpassword, setErrorConfirmerpassword] =
    useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameErorr] = useState<string>();
  const [RestorentFilter, setRestorentFilter] = useState("");
  const [isPasswordModified, setIsPasswordModified] = useState(false);
  const [isOldPasswordModified, setIsOldPasswordModified] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);


  useEffect(() => {
    const data = props.getmyprofile();
  
  }, []);
useEffect(() => {
  if(props.errorMessage){
    console.log("onkErppr",props.errorMessage)
    sethasErrorInput(true);
    setErrorPassword(props.errorMessage)
  }
},[props.errorMessage])

  console.log("objecterrorPassword",errorPassword)

  const handleShowPwFields = () => {
    SetShowPwFields(!ShowPwFields);
    setPassword("");
    setOldPassword("");
    setConfirmerpassword("");
    setIsPasswordModified(false);
    setIsOldPasswordModified(false);
  };

  useEffect(() => {
    if (props.userInfo && Object.keys(props.userInfo).length !== 0) {
      setFirstName(props.userInfo?.firstName);
      setLastName(props.userInfo?.lastName);
      setPhoneNumber(props.userInfo?.phoneNumber);
      setEmail(props.userInfo?.email);
    } else {
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setEmail("");
    }
  }, [props.userInfo, setFormSubmitted]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sethasErrorInput(false);

    if (password !== Confirmerpassword) {
      console.log("objectpassword",password , Confirmerpassword)
      sethasErrorInput(true);
      setErrorConfirmerpassword("Confirme password incorrect");
      return;
    }
    const formData = {
      firstName,
      lastName,
      ...(isPasswordModified && { password }),
      ...(isOldPasswordModified && { oldPassword }),
    };

    const data = await props.editmyprofile(formData);
    if (
      (data && data.firstName !== "" && data.lastName !== "") ||
      (data &&
        data.firstName !== "" &&
        data.lastName !== "" &&
        data.password !== "" &&
        data.oldPassword !== "")
    ) {
      // If success, set a state variable to display success message
      setSuccessMessage(true);
    }
    // Validate first name
    const isFirstNameValid = validation.ValidateName(firstName);
    if (firstName?.trim() === "") {
      sethasErrorInput(true);
      setFirstNameError("Saisissez votre prénom");
      return;
    } else if (!isFirstNameValid) {
      sethasErrorInput(true);
      setFirstNameError("Veuillez saisir un prénom valide");
      return;
    } else {
      setFirstNameError("");
    }

    // Validate last name
    const isLastNameValid = validation.ValidateName(lastName);
    if (lastName?.trim() === "") {
      sethasErrorInput(true);
      setLastNameErorr("Veuillez saisir un nom valide");
      return;
    } else if (!isLastNameValid) {
      sethasErrorInput(true);
      setLastNameErorr("Veuillez saisir un nom de famille valide");
      return;
    } else {
      setLastNameErorr("");
    }
    if (phoneNumber?.trim() === "") {
      sethasErrorInput(true);
      return;
    }
    if (email === "") {
      sethasErrorInput(true);
      return;
    }
    // Check if password is empty
    if (password !== "" && oldPassword === "") {
      sethasErrorInput(true);
      setErrorOldPassword("Please enter your old password");
      return;
    }


    // Check password validation criteria
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated
    ) {
      setErrorPassword(
        "Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre, un caractère spécial et comporter au moins 8 caractères"
      );
      return;
    } else {
      setErrorPassword("");
    }

    if (oldPassword === "") {
      sethasErrorInput(true);
      return;
    }

    // Reset the form after submission if needed
    setFormSubmitted(true);
    // setFirstName("");
    // setLastName("");
    //setBirthDate("");
    //setPhoneNumber("");
    //setEmail("");
    setPassword("");
    setConfirmerpassword("")
    setOldPassword("");
    setIsPasswordModified(false);
    setIsOldPasswordModified(false);
  };

  const handleChange = (value: any) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{8,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
    setIsPasswordModified(true);
    setPassword(value);
  };
  const handleNavigateFromProfile = () => {
    if (props.location?.shownAddress && props.location?.shownAddress !== "") {
      props.navigate("/restaurants");
    } else {
      props.navigate("/");
    }
  };
  return (
    <div id="MyProfile">
      <NavBar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />
      <div className="Profile-content">
        <div className="TopPageHeader">
          <div>
            <img onClick={handleNavigateFromProfile} src={LeftArrow} alt="" />
            <p>{languageData.fr.MonProfile.str_topPageHeader}</p>
          </div>
        </div>

        <div className="profile-body">
          <p className="body-title">
            {languageData.fr.MonProfile["str_body-title"]}
          </p>
          <form onSubmit={handleSubmit}>
            <div className="inp-col">
              <label>{languageData.fr.MonProfile.str_prenom}</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            {hasErrorInput && <p style={{ color: "red" }}>{firstNameError}</p>}
            <div className="inp-col">
              <label>{languageData.fr.MonProfile.str_nom}</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            {hasErrorInput && <p style={{ color: "red" }}>{lastNameError}</p>}

            {/* <div className="inp-col">
              <label>Date de naissance</label>
              <input
                style={{ textIndent: "8px" }}
                type="date"
                id="birthDate"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
              />
            </div> */}
            <div className="inp-col">
              <label>{languageData.fr.MonProfile.str_phoneNumber}</label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled
              />
            </div>
            {/* <p style={{ color: "red" }}>{hasErrorInput && hasErrorServer}</p> */}
            <div className="inp-col">
              <label>{languageData.fr.MonProfile.str_email}</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </div>
            {/* <p style={{ color: "red" }}>{hasErrorInput && hasErrorServer}</p> */}
            <div className={`pwSection ${ShowPwFields ? "ShowPwFields" : ""}`}>
              <img
                className="closeBtn"
                onClick={handleShowPwFields}
                src={CloseIcon}
                alt=""
              />
              <div onClick={handleShowPwFields} className="pwLabel">
                <img src={EditOrangeIcon} alt="" />
                <p>{languageData.fr.MonProfile.str_change_pw}</p>
              </div>
              <div className="pwFields">
                <div className="inp-col">
                  <label>{languageData.fr.MonProfile.str_ancien_pw}</label>
                  <input
                    type={type}
                    id="password"
                    value={oldPassword}
                    placeholder="********************************"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>
                {hasErrorInput && (
                  <p style={{ color: "red" }}>{errorPassword}</p>
                )}
                <div className="inp-col">
                  <label>{languageData.fr.MonProfile.str_new_pw}</label>
                  <input
                    type={type}
                    id="password"
                    value={password}
                    placeholder="********************************"
                    onChange={(e) => {
                      setIsOldPasswordModified(true);
                      handleChange(e.target.value);
                    }}
                  />
                </div>
                {hasErrorInput && (
                  <p style={{ color: "red" }}>{errorConfirmerpassword}</p>
                )}
                <div className="inp-col">
                  <label>{languageData.fr.MonProfile.str_confirm_pw}</label>
                  <input
                    type={type}
                    id="password"
                    value={Confirmerpassword}
                    placeholder="********************************"
                    onChange={(e) => setConfirmerpassword(e.target.value)}
                  />
                </div> 
                {hasErrorInput && (
                  <p style={{ color: "red" }}>{errorConfirmerpassword}</p>
                )}
                {/* {hasErrorInput && (
                  <p style={{ color: "red" }}>{errorPassword}</p>
                )} */}
              </div>
            </div>
            <div className="profileFooter">
              {successMessage && !hasErrorInput ? (
                <div className="successMsg">
                  {hasErrorInput? '' : <p>Vos informations a été mis a jour avec succès </p>}
                  <img src={ValidateIcon} alt="" />
                </div>
              ) : null}
              <button className="submit" type="submit">
                {languageData.fr.MonProfile.str_save_pw}
              </button>
            </div>
          </form>

          {/* <button onClick={()=>logoutUser()}> logout</button> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    token: state.auth.token,
    userInfo: state.auth.userInfo,
    errorMessage:state.auth.errorMessage
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getmyprofile: () => dispatch(getmyprofile()),
    editmyprofile: (user: any) => dispatch(editMyProfile(user)),
    logout: () => dispatch(logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(MonProfile));
