import moment from "moment";

export const buildOrderObject = (cart) => {
  let order = [];

  cart.map((entry) => {
    order.push({
      item: entry.Name,
      quantity: entry.Quantity,
      unitPrice: entry.Price || entry.totalCost,
      amount: entry.totalCost,
    });
  });
  return order;
};

const generateSingleProductCommand = (cartEntry, quantity) => {
  return {
    Q: 1,
    T: 1,
    Pr: [
      {
        Rf: cartEntry.id,
        Nm: cartEntry.name,
        Pr: cartEntry.isLoyaltyApplyed
          ? cartEntry.Price + cartEntry.loyaltyPointsPrice
          : cartEntry.isPromoApplied
            ? cartEntry.price + cartEntry.pcTotalRemiseMt
            : cartEntry.price,
        Q: quantity,
        cfpp: cartEntry.cpFideliteProd,
        cfpt: cartEntry.cpFideliteRetrait,
        cfpr: cartEntry.cpFidelitePrice,
        pcrmp: cartEntry.isLoyaltyApplyed,
        pcrm: cartEntry.isLoyaltyApplyed,
        pctrmp: cartEntry.pcTotalRemisePct,
        pctrmm: cartEntry.pcTotalRemiseMt,
        ofd: cartEntry.idPcAchatOffert,
        ona: cartEntry.ofNbAc,
        onf: cartEntry.ofNbOf,
        ofap: cartEntry.ofIsApply,
      },
    ],
  };
};

const generateComposableProductCommand = (cartEntry, isMenu, menuQuantity) => {
  let group = {
    Pr: [],
  };

  // Components are not grouped, submit one at a time with Quantity = 1
  cartEntry?.components.map((component) => {
    const compositions = [];
    if (component.numberOfSelection > 0) {
      if (component.hasCompositions)
        component?.compositions?.map((composition) => {
          if (composition.isDefault && composition?.defaultHidden) {
            compositions.push({
              Rf: composition.id,
              Dft: true,
              Ac: true,
              Nm: composition.name,
            });
          }
          if (composition?.numberOfSelection > 0 && !composition.isDefault) {
            //   alert('okk')
            console.log("BEFORE PAY", composition);
            compositions.push({
              Rf: composition.id,
              Nm: composition.name,
              Pr: composition.isPromoApplied
                ? composition.price + composition.pccTotalRemiseMt
                : composition.price,
              Q: composition.price === 0 ? 0 : composition.numberOfSelection,
              Dft: false,
              Ac: true,
              pcctrmp: composition.pccTotalRemisePct,
              pcctrmm: composition.pccTotalRemiseMt,
            });
          }
        });

      group.Pr.push({
        Rf: component.id,
        Nm: component.name,
        Pr: component.isLoyaltyApplyed
          ? component.price + component.loyaltyPointsPrice
          : component.isPromoApplied
            ? component.price + component.pcTotalRemiseMt
            : component.price,
        Q:
          cartEntry.name == "MENU"
            ? component.numberOfSelection
              ? component.numberOfSelection
              : 1
            : component.numberOfSelection,
        cfpp: component.cpFideliteProd,
        cfpt: component.cpFideliteRetrait,
        cfpr: component.cpFidelitePrice,
        pcrmp: component.pcRmsPc,
        pcrm: component.pcRmsMt,
        pctrmp: component.pcTotalRemisePct,
        pctrmm: component.pcTotalRemiseMt,
        hsC: compositions.length > 0,
        cmps: compositions,
        ofd: component.idPcAchatOffert,
        ona: component.ofNbAc,
        onf: component.ofNbOf,
        ofap: component.ofIsApply,
      });
    }
  });

  return group;
};

const buildAddress = (address) => {
  if (!address) return null;

  try {
    return {
      Glo: address.geometry.location.lng,
      Gla: address.geometry.location.lat,
      Gsn: address.address_components.find((adressComponent) =>
        adressComponent.types.find((value) => value === "street_number")
      ).long_name,
      Gsr: address.address_components.find((adressComponent) =>
        adressComponent.types.find((value) => value === "route")
      ).long_name,
      Gl: address.address_components.find((adressComponent) =>
        adressComponent.types.find((value) => value === "locality")
      ).long_name,
      Gda: address.address_components.find((adressComponent) =>
        adressComponent.types.find((value) => value === "locality")
      ).long_name,
      Gra: address.address_components.find((adressComponent) =>
        adressComponent.types.find(
          (value) => value === "administrative_area_level_1"
        )
      ).long_name,
      Gca: "France",
      Gcs: "FR",
      C: address.address_components.find((adressComponent) =>
        adressComponent.types.find((value) => value === "postal_code")
      ).long_name,
      fa: address.formatted_address,
      lf: address.formatted_address,
      sfa: "",
      bui: address.building,
      dr: address.door,
      drc: address.doorCode,
      stc: address.stairCaise,
      intr: address.intercom,
    };
  } catch (e) {
    return null;
  }
};

export const generateCommand = (
  cart,
  restaurant,
  remise,
  totalCost,
  totalPoints,
  totalPointsApplyed,
  diningMode,
  client,
  address,
  decalage
) => {
  let command = {
    Em: diningMode == 0,
    Lv: false,

    G: [],

    Mc: restaurant.idMachine,
    Pv: restaurant.idPv,
    Rs: restaurant.idRestau,
    Us: restaurant.idUser,
    Tt: totalCost,
    Tn: totalCost,
    fpt: totalPoints,
    ftt: totalPointsApplyed,
    Cl: {
      Fn: client.firstName,
      Ln: client.lastName,
      Em: client.email,
      PhN: client.phoneNumber,
      Sk: client.LoyaltySecretKey ?? null,
      QCd: client.QrCode ?? null,
    },
    IdPr: remise ? remise.IdPromo : 0,
    Rm: {
      IsP: remise ? remise.IsPct : null,
      M: remise ? remise.Mnt : null,
      V: true,
      Min: 0,
    },
    Adr: address ? buildAddress(address) : null,

    Dch: decalage
      ? moment(decalage).format("MM/DD/yyyy, hh:mm:ss A")
      : "Invalid Date",
    Sp: decalage ? false : true,
  };

  cart.map((entry) => {
    /*   if (entry.type === "single") {
        command.G.push(generateSingleProductCommand(entry))
      } */
    let entryGroup = {
      Q: entry.quantity,
      T: entry.amount,
      Pr: [],
    };
    if (entry.products == null || entry.products?.length == 0) {
      command.G.push(generateSingleProductCommand(entry, entry.quantity ?? 1));
    } else if (entry.products != null) {
      entry.products?.map((product) => {
        if (false) {
          command.G.push(
            generateSingleProductCommand(product.components[0], entry.quantity)
          );
        } else if (product.numberOfSelection > 0 || product.name == "MENU") {
          entryGroup.Pr = [
            ...entryGroup.Pr,
            ...generateComposableProductCommand(product, true, entry.quantity)
              .Pr,
          ];
        }
      });
      command.G.push(entryGroup);
    }
  });
  return command;
};
