import * as actionTypes from "../actions/actionTypes";

const initialState = {
  menuLoading: true,
  menu: null,
  menuAvailableMode: null,
  mode: 0,
  selectedCategory: null,
  selectedCompositions: null,
  menuId: null,
  productHasCompositionId: null,
  initialMenu: null,
  activeRestaurant: null,
  activeRestaurantId: null,
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_RESTAURANT_MENU:
      return {
        ...state,
        menu: null,
        menuLoading: true,
        activeRestaurantId: action.body.id,
      };

    case actionTypes.GET_RESTAURANT_MENU_RESET:
      return {
        ...state,
        menu: null,
        menuLoading: true,
        mode: null,
        menuAvailableMode: null,
      };

    case actionTypes.GET_RESTAURANT_MENU_SUCCESS:
      return {
        ...state,
        menuAvailableMode: action.modeTypes,
        menuLoading: false,
        menu: action.menu,
        initialMenu: action.menu,
        mode:
          state.mode === null
            ? action.modeTypes == 0
              ? 0
              : action.modeTypes == 1
                ? 0
                : action.modeTypes == 2
                  ? 1
                  : 0
            : state.mode,
      };

    case actionTypes.SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        activeRestaurant: action.restaurant,
      };

    case actionTypes.GET_RESTAURANT_CHANGE_MODE:
      return {
        ...state,
        menuLoading: true,
        menu: null,
        mode: action.value,
      };
    case actionTypes.CLEAR_STORE_ITEM: {
      var key = action?.item;
      var obj: any = {};
      obj[key] = null;

      return {
        ...state,
        ...obj,
      };
    }

    case actionTypes.SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.category,
        menuId: action.menuId,
        activeRestaurantId: action.restaurantId,
      };

    case actionTypes.SELECT_COMPOSITION:
      return {
        ...state,
        selectedProductWithComposition: action.product,
        productHasCompositionId: action.productHasCompositionId,
        componentId: action.componentId,
      };

    case actionTypes.UPDATE_CURRENT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.category,
      };

    case actionTypes.RESET_CATEGORY:
      return {
        ...state,
        // selectedCategory: null,
      };

    case actionTypes.UPDATE_CURRENT_PRICE:
      return {
        ...state,
        selectedCategory: state.selectedCategory,
      };

    default:
      return state;
  }
}

export default reducer;
