import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withNavigateHook = (Component: any) => {
  return (props: any) => {
    const navigate = useNavigate();
    let location = useLocation();
    let params = useParams();

    return <Component navigate={navigate} location={location} locationPathName={location.pathname} URLParams={params} {...props} />
  }
}

export default withNavigateHook;