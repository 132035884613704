import ReplayIcon from "@mui/icons-material/Replay";
import { Button } from "@mui/material";
import {
  ChangeEvent,
  FormEvent,
  ClipboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import withNavigateHook from "../../components/WithNavigation";
import { connect } from "react-redux";
import Eye from "./icons/eye.svg";

import {
  clearStoreItem,
  sendVerificationCode,
  updateLoading,
  updateTokens,
  updateUserAuth,
  verifyCode,
} from "../../store/actions/actions";
import parsePhoneNumberFromString from "libphonenumber-js";
import { validation } from "../../utils/Validation";
import "./style.scss";
import { languageData } from "../../i18n";
import { Link, useNavigate } from "react-router-dom";
import { setUserMode} from "../../store/actions/actions";
import { NavAuth } from "./nav";

interface phoneEmailInterface {
  filedInput: string;
  inputType: string | "email" | "tel";
  countryPrefix: string;
}

const PasswordPage = (props: any) => {

  console.log("userRedirectAfterLoginTo",props.userRedirectAfterLoginTo)
  const [phoneEmailState, setPhoneEmailState] = useState<phoneEmailInterface>({
    filedInput: "",
    inputType: "email",
    countryPrefix: "",
  });
  const [password, setPassword] = useState("");
  const [hasErrorServer, sethasErrorServer] = useState("");
  const [hasErrorInput, sethasErrorInput] = useState(false);
  const [UserWantBack, setUserWantBack] = useState(false);
  const [isFocused, setIsFocused] = useState(true);
  const [retries, setretries] = useState(0);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const navigate = useNavigate();
  
  const [ShowPw, SetPassword] = useState(false)
  let __hasNoForce = props.location?.state?.forUser == "none";

  const ValidateInput = () => {
    const phoneNumber = parsePhoneNumberFromString(
      "+" + phoneEmailState.countryPrefix + phoneEmailState.filedInput
    );
    const isMailValid = validation.ValidateEmail(phoneEmailState.filedInput);
    const isPhone =
      phoneNumber?.isValid() &&
      validation.ValidatePhonenumber(phoneEmailState.filedInput);

    return {
      isMailValid,
      isPhone,
    };
  };




  window.addEventListener("popstate", (event) => {
    console.log("User clicked the browser's back arrow");
      props.navigate("/");
});
 
  useEffect(() => {
  if (props.user === null || props.user.length === 0) {
    props.navigate('/Authentification');
  }
}, []);


  
  const sendVerifCode = () => {
    const validation = ValidateInput();
    const isPhone = validation.isPhone;
    let purePhone =
      "+" +
      phoneEmailState.countryPrefix +
      phoneEmailState.filedInput?.replace(" ", "");

    props.sendVerificationCode(
      {
        input: props?.user?.email ? props.user?.email : props?.user?.phone,
        hashCode: "0000",
        preferredMethod: props?.user?.email ? 0 : 1,
        mode: 1,
        password: password,
      },
      false
    );
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      sethasErrorServer('');
      sethasErrorInput(false);
      if (!password) {
          sethasErrorServer(languageData.fr.auth['login.header.pass.obligatoire']);
          sethasErrorInput(true);
      }  else {
          sethasErrorServer('');
          sethasErrorInput(false);
          sendVerifCode();
          setretries(retries + 1);
      }
  } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
  }
};

const handleNavigateToFogetPassword = ()=>{

  props.navigate('/Authentification/forget-password')
}
  useEffect(() => {
    const redirectedToAuth = localStorage.getItem("redirectedToAuth") === "true";
    if (props.sendVerificationCodeResponse) {
      const { token } = props.sendVerificationCodeResponse; // Assuming the token is stored in the 'token' field of the response

      if (token) {
        // Store the token in local storage
        localStorage.setItem("token", token);
      }
      if (password) {
        props.clearItemStore("sendVerificationCodeResponse");
        props.clearItemStore("resendVerificationCodeResponse");
        props.clearItemStore("authError");
        if (props.userRedirectAfterLoginTo) {
          // props.navigation.reset({
          //   index: 0,
          //   routes: [{ name: "TabRouteName" }, { name: props.userRedirectAfterLoginTo }]
          // })

          props.clearItemStore("userRedirectAfterLoginTo");
        } else if (props.location?.shownAddress && props.location?.shownAddress!== "") {
          props.navigate("/restaurants");
        }else{
          props.navigate("/");
        }
      }
      if (redirectedToAuth) {
        props.navigate("/paiement");
        return;
      }
    

    }
  }, [props.sendVerificationCodeResponse, retries]);




  useEffect(() => {
    if (props.authError && !props.sendVerificationCodeResponse) {
      if (props.authError == "Password incorrect") {
        sethasErrorInput(false);
        sethasErrorServer(languageData.fr.serverError["pawd.incorrect"]);
      }
    }
  }, [props.authError, props.authRetries]);

  return (
    <div className="authPage">
      <NavAuth />
      <div className="content pw_page">
        <p className="tite-form">Connexion</p>
        <form onSubmit={handleSubmit}>
          <div className="inp-col ">
            <label>Mot de passe *</label>
            <div className="pw-field">
              <input
                className="inp-underline"
                type={`${ShowPw ? "text" : "password"}`}
                value={password}
                onChange={handlePasswordChange}
              />
              {/* <img {()=>ShowPw(!SetPassword)} className="show-pw" src={Eye} alt="" /> */}
              <img onClick={() => SetPassword(!ShowPw)} className="show-pw" src={Eye} alt="" />
            </div>
          </div>
          <p className="error-msg">{hasErrorServer}</p>
          <p className="forgotPw"  onClick={handleNavigateToFogetPassword}>
          Mot de passe oublié?
          </p>
         
          <button className="btn-form continue" type="submit">Continuer</button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    location: state.auth.location,
    resendVerificationCodeResponse: state.auth.resendVerificationCodeResponse,
    verifyCodeSuccess: state.auth.verifyCodeSuccess,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    authRetries: state.auth.authRetries,
    authError: state.auth.authError,
    validationStepToken: state.auth.validationStepToken,
    validationSignupToken: state.auth.validationSignupToken,
    userRedirectAfterLoginTo: state.auth.userRedirectAfterLoginTo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserInfo: (userInfo: any) => dispatch(updateUserAuth(userInfo)),
    sendVerificationCode: (
      body: serviceAuthModels.SendVerificationCodeBody,
      resend: boolean
    ) => dispatch(sendVerificationCode(body, resend)),
    updateLoading: (value: boolean) => dispatch(updateLoading(value)),
    verifyCode: (body: serviceAuthModels.verifyCodeBody) =>
      dispatch(verifyCode(body)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    updateTokens: (verifToken: string, signupToken: string, token: any) =>
      dispatch(updateTokens(verifToken, signupToken, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(PasswordPage));
