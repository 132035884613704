import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { orderApi } from "../../services/apiGate";
import { getOrderByIdSuccess, getOrdersListSuccess } from "../actions/actions";

export function* getAllOrdersListSaga(action: any) {
  try {
    let response: AxiosResponse = yield orderApi.getOrdersList();

    if (response.data) {
      let data: { commandes: serviceCommandModels.commandeItem[] } =
        response.data;

      yield put(getOrdersListSuccess(data));
    }
  } catch (error: any) {}
}
export function* getOrderDetailsSaga(action: any) {
  try {
    let response: AxiosResponse = yield orderApi.getOrderById(action.orderId);

    if (response.data) {
      let data = response.data;

      yield put(getOrderByIdSuccess(data));
    }
  } catch (error: any) {}
}
