import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CartElements from "./CartElements";
import CartHeader from "./CartHeader";
import PanierIcon from "../../../images/shopping-bag-01.svg";
import CloseIcon from "../../../images/CloseIcon_btn.svg";
import "./cart.scss";
import AuthLoginModal from "./modals/AuthLoginModal";
import { connect } from "react-redux";
import { pushCategory, selectCategory } from "../../../store/actions/actions";
import CartData from "./CartData";
import { languageData } from "../../../i18n";
import RestaurantIcon from "../../../images/RestaurantIcon.svg";
import { shopApi } from "../../../services/apiGate";

const CartNav = (props: any) => {
  const [showPanier, setShowPanier] = useState( false);
  // const [showEmptyPanier, setShowEmptyPanier] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [category, setCategory] = useState<any>();
  const [idUsed, setIdUsed] = useState<number | undefined>(undefined);
  const navigate = useNavigate();
  const [ShowRestaurantError, setShowRestaurantError] = useState(false);
  const [blockBody, setblockBody] = useState(false);

  let { id } = useParams();
  const location = useLocation();

  const navigateToShope = () => {
    const isRestaurantsPage = location.pathname === `/restaurant/${id}`;
    if (!isRestaurantsPage) {
      navigate(`/restaurant/${id}`);
    } else {
      setShowPanier(!showPanier);
    }
  };
  console.log("category",category)

  const handelBodyScroll = () => {
    setblockBody((prevblockBody) => !prevblockBody);
  };
  let totalAmountwithouProducts = category?.categories
    .filter((c: any) => c.amount == 0)
    ?.reduce(
      (acc: number, curr: any) => (acc += curr?.price * curr?.quantity),
      0
    )
    .toFixed(2);

  useEffect(() => {
    if (id !== undefined) {
      const restaurantId = parseInt(id);
      setIdUsed(restaurantId);
      setCategory(props.savedCategories[restaurantId]);
    } else {
      console.error("No restaurant ID provided");
    }
  }, [id]);

  useEffect(() => {
    const restaurantId = id ? parseInt(id) : undefined;
    if (restaurantId && props.savedCategories[restaurantId]?.categories.length === 0) {
      setShowPanier(false);
    }
  }, [id, props.savedCategories]);

  // useEffect(() => {
  //   if (!showPanier) {
  //     localStorage.setItem("redirectedToPrevPage", "false");
  //   }
  // }, [showPanier]);
  

  useEffect(() => {
    const restaurantId = id ? parseInt(id) : undefined;
    if (restaurantId && props.savedCategories[restaurantId]?.categories.length > 0 && localStorage.getItem("redirectedToPrevPage") === "true") {
      setShowPanier(true);
    } else {
      setShowPanier(false);
    }
  }, [id, props.savedCategories]);
  
  
  useEffect(() => {
    if (location.pathname === `/restaurant/${id}`) {
      if (localStorage.getItem("redirectedToPrevPage") === "true") {
        setShowPanier(true);
      } else {
        setShowPanier(false);
      }
    }
  }, [showAuth]);


  useEffect(() => {
    const body = document.body;
    if (showPanier) {
      body.style.overflow = "hidden";
      //localStorage.setItem("redirectedToPrevPage", "false");
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [showPanier]);

  const totalAmounts = category?.categories
    ?.reduce(
      (acc: number, curr: any) => (acc += curr?.amount * curr?.quantity),
      0
    )
    .toFixed(2);

  const totalQuantity = category?.categories?.reduce(
    (acc: number, curr: any) => (acc += curr?.quantity),
    0
  );
  const handleCommand = async (id: number) => {
    let disponible = await checkRestaurantDisponibilty(id);
    if (disponible) {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        localStorage.setItem("redirectedToAuth", "true");
        navigate("/Authentification");
      } else {
        localStorage.setItem("redirectedToAuth", "false");
        navigate("/paiement");
      }
    } else {
      setShowRestaurantError(!ShowRestaurantError);
    }
  };

  const totalAmountPrice = (
    (parseFloat(totalAmounts) || 0) +
    (parseFloat(totalAmountwithouProducts) || 0)
  ).toFixed(2);
  const checkRestaurantDisponibilty = async (id: number) => {
    const restaurantApi = await shopApi.getResturantById({
      id: id,
      diningMode: props.mode,
      adresse: {
        latitude: props.location?.lat,
        longitude: props.location?.lng,
      },
    });
    return (
      restaurantApi.data?.restaurant?.isEnabled &&
      restaurantApi.data?.restaurant?.isOpen
    );
  };
  return (
    <div className={`CartNav ${showPanier ? "showBox" : ""}`}>
      <div
        className="panier-btn"
        onClick={() => {
          setShowPanier(!showPanier);
          localStorage.setItem("redirectedToPrevPage", "false");
          handelBodyScroll();

        }}
      >
        <div className="panier-el">
          <img src={PanierIcon} alt="" />
          <p className="alias-panier">Panier</p>
          <b style={{ color: "white" }}>•</b>
        </div>
        <p className="products-count">
          {totalQuantity > 0 || totalQuantity !== undefined ? totalQuantity : 0}
        </p>
      </div>
      <div
        onClick={() => setShowPanier(!showPanier)}
        className="overlay-box hide"
      ></div>
      {/* <div className="CartData">
        <div className="closeBtn">
          <img
            className=""
            src={CloseIcon}
            alt="CloseIcon"
            onClick={() => setShowPanier(!showPanier)}
          />
        </div>
        <div className="CartComponents">
          <CartHeader category={category} location={props.location} />
          <CartElements category={category} idUsed={idUsed} />
        </div>
        <div className="CartFooter">
          <button className="btn-command" onClick={handleCommand}>
            Commander • {totalAmount}€
          </button>
          <button
            onClick={navigateToShope}
            className="btn-add-articles"
          >
            Ajouter article(s){" "}
          </button>
        </div>
      </div> */}
      <CartData

        handleCloseIcon={() =>
          localStorage.setItem("redirectedToPrevPage", "true")
        }
        handleCloseIconExit={() =>
          localStorage.setItem("redirectedToPrevPage", "false")
        }
        setShowCommandDetails={() => setShowPanier(false)}
        ShowCommandDetails={showPanier}
        category={category}
        idUsed={idUsed}
        handleCommand={handleCommand}
        totalAmount={totalAmountPrice}
        navigateToShope={navigateToShope}
      />
      <div className={`AuthModal ${showAuth ? "ShowAuth" : ""}`}>
        <div
          className="overlay-black"
          onClick={() => {
            setShowAuth(!showAuth);
            localStorage.setItem("redirectedToPrevPage", "false");
          }}
        ></div>

        <AuthLoginModal />
      </div>
      <div className={`restaurantError ${ShowRestaurantError ? "" : "hide"}`}>
        <div
          className="overlay-modal"
          onClick={() => setShowRestaurantError(!ShowRestaurantError)}
        ></div>
        <div className="modalContent">
          <div className="wrapper">
            <img
              className="close-btn"
              src={CloseIcon}
              alt=""
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            />
            <div>
              <p className="modalTitle">
                {languageData.fr.ModalRestaurantError.modalTitle}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RestaurantIcon} alt="" />
                <p className="modalDescription">
                  {languageData.fr.ModalRestaurantError.ModalDescription}
                </p>
              </div>
            </div>

            <button
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            >
              {languageData.fr.ModalRestaurantError.ModalButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    activeRestaurantId: state.menu.activeRestaurantId,
    preSavedCart: state.cart.preSavedCart,
    savedCategories: state.cart.savedCategories,
    menuId: state.menu.menuId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectCategory: (
      category: serviceMenuModels.Category,
      menuId: number,
      restaurantId: number
    ) => dispatch(selectCategory(category, menuId, restaurantId)),
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartNav);
