export const UPDATE_USER_LOCATION = "UPDATE_USER_LOCATION";
export const UPDATE_USER_AUTH = "UPDATE_USER_AUTH";
export const UPDATE_LOADER = "UPDATE_LOADER";
export const EDIT_MY_PROFILE_FAILURE = "EDIT_MY_PROFILE_FAILURE";
export const SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE";
export const SEND_VERFICATION_CODE_SUCCESS = "SEND_VERFICATION_CODE_SUCCESS";
export const UPDATE_TOKENS = "UPDATE_TOKENS";
export const RESEND_VERFICATION_CODE_SUCCESS =
  "RESEND_VERFICATION_CODE_SUCCESS";
export const VERIFY_CODE = "VERIFY_CODE";
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const CLEAR_STORE_ITEM = "CLEAR_STORE_ITEM";
export const SINGUP = "SINGUP";
export const SINGUP_SUCCESS = "SINGUP_SUCCESS";
export const SET_USER_MODE = "SET_USER_MODE";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_ERROR_EDIT = "AUTH_ERROR_EDIT";
export const RESET_USER = "RESET_USER";
export const USER_LOGOUT = "USER_LOGOUT";
export const SET_IP_GEO = "SET_IP_GEO";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const EDIT_MY_PROFILE = "EDIT_MY_PROFILE";
export const EDIT_MY_PROFILE_SUCCESS = "EDIT_MY_PROFILE_SUCCESS";
export const EDIT_MY_PROFILE_SUCCESS_RESET = "EDIT_MY_PROFILE_SUCCESS_RESET";
export const USER_REDIRECT_AFTER_LOGIN_TO = "USER_REDIRECT_AFTER_LOGIN_TO";
export const VERIFY_GOOGLE = "VERIFY_GOOGLE";
export const VERIFY_APPLE = "VERIFY_APPLE";
export const VERIFY_GOOGLE_SUCCESS = "VERIFY_GOOGLE_SUCCESS";
export const VERIFY_APPLE_SUCCESS = "VERIFY_APPLE_SUCCESS";
export const UPDATE_3D_THIRD_AUTH = "UPDATE_3D_THIRD_AUTH";

export const GET_FILTRED_SHOP = "GET_FILTRED_SHOP";
export const GET_FILTRED_SHOP_SUCCESS = "GET_FILTRED_SHOP_SUCCESS";
export const CHANGE_SHOPS_MODE = "CHANGE_SHOPS_MODE";
export const GET_RESTAURANT_MENU = "GET_RESTAURANT_MENU";
export const SET_ACTIVE_RESTAURANT = "SET_ACTIVE_RESTAURANT";
export const GET_RESTAURANT_MENU_SUCCESS = "GET_RESTAURANT_MENU_SUCCESS";
export const GET_RESTAURANT_CHANGE_MODE = "GET_RESTAURANT_CHANGE_MODE";
export const GET_RESTAURANT_MENU_RESET = "GET_RESTAURANT_MENU_RESET";
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const ADD_SHOP_TO_FAVORITE = "ADD_SHOP_TO_FAVORITE";
export const GET_FAVORITES_FILTRED_SHOP_SUCCESS =
  "GET_FAVORITES_FILTRED_SHOP_SUCCESS";
export const GET_FAVORITES_FILTRED_SHOP = "GET_FAVORITES_FILTRED_SHOP";
export const CHANGE_FILTER_RESTAURANTS_INPUT =
  "CHANGE_FILTER_RESTAURANTS_INPUT";

export const GET_CATEGORIES_LIST = "GET_CATEGORIES_LIST";
export const GET_CATEGORIES_LIST_SUCCESS = "GET_CATEGORIES_LIST_SUCCESS";
export const GET_CATEGORY_SHOP_LIST_SUCCESS = "GET_CATEGORY_SHOP_LIST_SUCCESS";
export const GET_CATEGORY_SHOP_LIST = "GET_CATEGORY_SHOP_LIST";
export const CHANGE_CATEGORY_SHOP_MODE = "CHANGE_CATEGORY_SHOP_MODE";
export const CHANGE_CURRENT_CATEGORY = "CHANGE_CURRENT_CATEGORY";
export const SELECT_COMPOSITION = "SELECT_COMPOSITION";
export const UPDATE_CURRENT_CATEGORY = "UPDATE_CURRENT_CATEGORY";

export const UPDATE_HOME_LOADING = "UPDATE_HOME_LOADING";
export const UPDATE_CATEGORY_LOADING = "UPDATE_CATEGORY_LOADING";

export const UPDATE_CART = "UPDATE_CART";
export const PRE_SAVE_CART = "PRE_SAVE_CART";
export const UPDATE_CURRENT_PRICE = "UPDATE_CURRENT_PRICE";
export const UPDATE_COMPOSITION_PRICE = "UPDATE_COMPOSITION_PRICE";
export const SAVE_CART = "SAVE_CART";
export const RESET_CATEGORY = "RESET_CATEGORY";
export const UPDATE_CATEGORY_COUNT = "UPDATE_CATEGORY_COUNT";
export const PUSH_CATEGORY = "PUSH _CATEGORY";

export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";

export const SELECT_ORDER = "SELECT_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const UPDATE_SAVED_CART = "UPDATE_SAVED_CART";

export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAIL = "CREATE_PAYMENT_FAIL";
export const UPDATE_CART_LIST = "UPDATE_CART_LIST"
export const UPDATE_CART_LIST_BY_ID = "UPDATE_CART_LIST_BY_ID"