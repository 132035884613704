import "./style.scss";
import { Grid, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  clearStoreItem,
  currentCategoryChange,
  getAllCategories,
} from "../../store/actions/actions";
import { useTheme } from "@mui/material/styles";
// import Headroom from 'react-headroom';
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { config } from "../../config";
import RatioProvider from "../RatioProvider";
import { AspectRatio, Skeleton, Stack, Typography } from "@mui/joy";
import SkeletonElement from "../../apps/skeleton/SkeletonElement";

const imageBaseUrl = config.api_baseUrl_Picture;

// type categoryType = {
//     id: number,
//     categorie: string,
//     imageUrl: string
// }

// const getAllCatResponse = {
//     "categories": [
//         {
//             "id": 1,
//             "categorie": "Burger",
//             "imageUrl": "foods/hotdog.png"
//         },
//         {
//             "id": 2,
//             "categorie": "Pizza",
//             "imageUrl": "foods/pizza.png"
//         },
//         {
//             "id": 3,
//             "categorie": "Asiatique",
//             "imageUrl": "foods/taco.png"
//         },
//         {
//             "id": 4,
//             "categorie": "Crêpes ",
//             "imageUrl": "foods/buritto.png"
//         },
//         {
//             "id": 5,
//             "categorie": "Turque",
//             "imageUrl": "foods/cofee.png"
//         }
//     ]
// }

function CategoryWheel(props: any) {
  
  const [value, setValue] = useState<number | null>(props.currentCategory[0]?props.currentCategory[0]: null);
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const theme = useTheme();




  const handleCateClick = (id: number) => {
    //props.changeCurrentCategory([])
    props.clearItemStore("shops")

    props.changeCurrentCategory([id]);
    
  };

  // useEffect(()=>{
  //   resetCate()
  // },[])

  const resetCate = () => {
    props.changeCurrentCategory([]);
  };

  useEffect(() => {
    props.getAllCategories();
  }, []);
  const [imageLoaded, setImageLoaded] = useState(false);

  return !props.categoriesLoading ? (
    <>
      <div>
        <Tabs
          className="tabContainer row menu-categories-restaurant"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          selectionFollowsFocus
          centered
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            marginLeft: "-15px",
            paddingLeft: "0",
            paddingRight: "0",
            textTransform: "lowercase",
            maxWidth: "1440px",
            margin: "0 auto",
            "& .MuiTabs-scrollButtons": {
              width: "25px",
              color: "white",
              backgroundColor: "#EE8113",
              height: "25px",
              borderRadius: "120%",
              marginTop: "15px",
            },
          }}
        >
          <Tab
            value={null}
            icon={
              <svg
                style={{
                  width: theme.breakpoints.up("md") ? "30px" : "20px",
                  height: theme.breakpoints.up("md") ? "30px" : "20px",
                }}
                width="38"
                height="34"
                viewBox="0 0 38 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.45 1.625H7.59167C6.72825 1.625 6.29655 1.625 5.96676 1.79303C5.67668 1.94084 5.44084 2.17668 5.29303 2.46676C5.125 2.79655 5.125 3.22825 5.125 4.09167V9.95C5.125 10.8134 5.125 11.2451 5.29303 11.5749C5.44084 11.865 5.67668 12.1008 5.96676 12.2486C6.29655 12.4167 6.72825 12.4167 7.59167 12.4167H13.45C14.3134 12.4167 14.7451 12.4167 15.0749 12.2486C15.365 12.1008 15.6008 11.865 15.7486 11.5749C15.9167 11.2451 15.9167 10.8134 15.9167 9.95V4.09167C15.9167 3.22825 15.9167 2.79655 15.7486 2.46676C15.6008 2.17668 15.365 1.94084 15.0749 1.79303C14.7451 1.625 14.3134 1.625 13.45 1.625Z"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M30.4083 1.625H24.55C23.6866 1.625 23.2549 1.625 22.9251 1.79303C22.635 1.94084 22.3992 2.17668 22.2514 2.46676C22.0833 2.79655 22.0833 3.22825 22.0833 4.09167V9.95C22.0833 10.8134 22.0833 11.2451 22.2514 11.5749C22.3992 11.865 22.635 12.1008 22.9251 12.2486C23.2549 12.4167 23.6866 12.4167 24.55 12.4167H30.4083C31.2717 12.4167 31.7035 12.4167 32.0332 12.2486C32.3233 12.1008 32.5592 11.865 32.707 11.5749C32.875 11.2451 32.875 10.8134 32.875 9.95V4.09167C32.875 3.22825 32.875 2.79655 32.707 2.46676C32.5592 2.17668 32.3233 1.94084 32.0332 1.79303C31.7035 1.625 31.2717 1.625 30.4083 1.625Z"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M30.4083 18.5833H24.55C23.6866 18.5833 23.2549 18.5833 22.9251 18.7514C22.635 18.8992 22.3992 19.135 22.2514 19.4251C22.0833 19.7549 22.0833 20.1866 22.0833 21.05V26.9083C22.0833 27.7717 22.0833 28.2035 22.2514 28.5332C22.3992 28.8233 22.635 29.0592 22.9251 29.207C23.2549 29.375 23.6866 29.375 24.55 29.375H30.4083C31.2717 29.375 31.7035 29.375 32.0332 29.207C32.3233 29.0592 32.5592 28.8233 32.707 28.5332C32.875 28.2035 32.875 27.7717 32.875 26.9083V21.05C32.875 20.1866 32.875 19.7549 32.707 19.4251C32.5592 19.135 32.3233 18.8992 32.0332 18.7514C31.7035 18.5833 31.2717 18.5833 30.4083 18.5833Z"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.45 18.5833H7.59167C6.72825 18.5833 6.29655 18.5833 5.96676 18.7514C5.67668 18.8992 5.44084 19.135 5.29303 19.4251C5.125 19.7549 5.125 20.1866 5.125 21.05V26.9083C5.125 27.7717 5.125 28.2035 5.29303 28.5332C5.44084 28.8233 5.67668 29.0592 5.96676 29.207C6.29655 29.375 6.72825 29.375 7.59167 29.375H13.45C14.3134 29.375 14.7451 29.375 15.0749 29.207C15.365 29.0592 15.6008 28.8233 15.7486 28.5332C15.9167 28.2035 15.9167 27.7717 15.9167 26.9083V21.05C15.9167 20.1866 15.9167 19.7549 15.7486 19.4251C15.6008 19.135 15.365 18.8992 15.0749 18.7514C14.7451 18.5833 14.3134 18.5833 13.45 18.5833Z"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            onClick={resetCate}
            label={"tout"}
            sx={{
              padding: "0",
              width: "5px",
              height: "5px",
              minWidth: { xs: "60px", md: "90px" },
              textTransform: "capitalize",
            }}
          />
          {props.categories &&
            [...props.categories]?.map((cate: any, i: number) => {
              return (

                <Tab
                  key={i}
                  value={cate.id}
                  icon={
                    <img
                      src={`${imageBaseUrl}/${cate.imageUrl}`}
                      alt=".."
                      style={{
                        width: theme.breakpoints.up("md") ? "30px" : "20px",
                        height: theme.breakpoints.up("md") ? "30px" : "20px",
                      }}
                      onLoad={() => setImageLoaded(true)}
                    />
                  }
                  onClick={() => handleCateClick(cate.id)}
                  label={cate.categorie.toLowerCase()}
                  sx={{
                    textTransform: "capitalize",
                    padding: "0",
                    width: "5px",
                    height: "5px",
                    minWidth: { xs: "60px", md: "90px" },
                  }}
                />
              );
            })}
        </Tabs>
      </div>
    </>
  ) : (
    // <Stack direction={"row"} spacing={4} padding={"0 30px"} overflow={"hidden"}>
    //   {Array.from({ length: 6 }, (index: number) => {
    //     return (
    //       <Stack
    //         direction={"column"}
    //         spacing={1}
    //         alignItems={"center"}
    //         key={index}
    //       >
    //         <AspectRatio ratio={1} sx={{ width: "80px" }}>
    //           <Skeleton variant={"rectangular"}></Skeleton>
    //         </AspectRatio>
    //         <Typography level="title-sm">
    //           <Skeleton overlay>Category</Skeleton>
    //         </Typography>
    //       </Stack>
    //     );
    //   })}
    // </Stack>
    <div></div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    categoriesLoading: state.category.categoriesLoading,
    categories: state.category.categories,
    currentCategory: state.category.currentCategory,
    shopMode: state.shop.mode,
    shop:state.shop.shops
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllCategories: () => dispatch(getAllCategories()),
    changeCurrentCategory: (value: number) =>
      dispatch(currentCategoryChange(value)),
      clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryWheel);
