import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Fragment, useState } from "react";
import {
  updateOrder,
  updateCurrentPrice,
  updateCartList,
} from "../../store/actions/actions";
import {
  Avatar,
  Button,
  Chip,
  Container,
  FormControlLabel,
  IconButton,
  Menu,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import "./style.scss";
import { connect } from "react-redux";
import withNavigateHook from "../WithNavigation";
import { config } from "../../config";
import axios from "axios";
import { shopApi } from "../../services/apiGate";

type cartType = {
  restaurants:
    | [
        {
          id: number;
          name: string;
          image: string;
          totalPrice: number;
          orderedCategories: [
            {
              categoryId: number;
              categoryName: string;
              baseUnitPrice: number;
              image: string;
              quantity: number;
              finalTotalPrice: number;
              selectedProducts: [
                {
                  productId: number;
                  productName: string;
                  image: string;
                  price: number;
                  quantity: number;
                  components: [
                    {
                      componentId: number;
                      componentNmae: string;
                      hasComposition: boolean;
                    }
                  ];
                }
              ];
            }
          ];
          specialities: string;
        }
      ]
    | any;
};

const calculatePrice: any = (obj: any) => {
  return 0;
};

const cartExample: cartType = {
  restaurants: [
    {
      id: 1116,
      name: "FivePizza Showroom",
      image: "FPO-banner.jpg",
      totalPrice: 36,
      orderedCategories: [
        {
          categoryId: 1116,
          categoryName: "REINE DOUNIA",
          baseUnitPrice: 6.9,
          image:
            "https://gmpvfio.frenchinnov.fr:443/CacheUploadImages/PIZZAS/ddsbqaxa2tk_20230614101149332_900x700.png ",
          quantity: 2,
          finalTotalPrice: 13.8,
          selectedProducts: [
            {
              productId: 87,
              productName: "MENU",
              image: null,
              productQuantity: 10,
              selectedComponents: [
                {
                  componentId: 93270,
                  componentName: "REINE",
                  hasCompositions: true,
                  componentQuantity: 0,
                  selectedCompositions: [
                    {
                      compositionId: 54186,
                      compositionName: "mafroum",
                      compositionQuantity: 2,
                      image: "bla bla ",
                      price: 0,
                    },
                    {
                      compositionId: 54186,
                      compositionName: "mafroum",
                      compositionQuantity: 2,
                      image: "bla bla ",
                      price: 0,
                    },
                    {
                      compositionId: 54186,
                      compositionName: "mafroum",
                      compositionQuantity: 2,
                      image: "bla bla ",
                      price: 0,
                    },
                  ],
                },
              ],
            },
            {
              productId: 87,
              productName: "TAILLE",
              image: null,
              productQuantity: 10,
              selectedComponents: [
                {
                  componentId: 93270,
                  componentName: "Taille M",
                  hasCompositions: false,
                  componentQuantity: 0,
                  selectedCompositions: [],
                },
                {
                  componentId: 93270,
                  componentName: "Taille L",
                  hasCompositions: false,
                  componentQuantity: 2,
                  selectedCompositions: [],
                },
              ],
            },

            {
              productId: 87,
              productName: "supléments",
              image: null,
              productQuantity: 10,
              selectedComponents: [
                {
                  componentId: 93270,
                  componentName: "REINE",
                  hasCompositions: true,
                  componentQuantity: 0,
                  selectedCompositions: [
                    {
                      compositionId: 54186,
                      compositionName: "mafroum",
                      compositionQuantity: 2,
                      image: "bla bla ",
                      price: 0,
                    },
                    {
                      compositionId: 54186,
                      compositionName: "mafroum",
                      compositionQuantity: 2,
                      image: "bla bla ",
                      price: 0,
                    },
                    {
                      compositionId: 54186,
                      compositionName: "mafroum",
                      compositionQuantity: 2,
                      image: "bla bla ",
                      price: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const drawerWidth = 450;

const baseUrl = "http://40.127.178.155:5000/uploads/";






const RestaurantListView = (props: any) => {
  const array = props.array;

  return (
    <Stack>
      {array.map((val: any, index: number) => {
        return (
          <Stack key={index} spacing={1}>
            <Button
              className="full-width row"
              style={{
                justifyContent: "space-between",
                textTransform: "capitalize",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  border: "solid 1px gray",
                  borderRadius: "100px",
                  height: "80px",
                  width: "80px",
                  backgroundImage: `url(${baseUrl + val.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  alignSelf: "flex-start",
                }}
              ></Paper>

              <Stack
                direction={"column"}
                spacing={1}
                className=""
                sx={{ flex: 1, padding: "0 10px" }}
              >
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <Stack spacing={0.5} direction={"column"} sx={{lineHeightStep:"0.5px" ,"& span": { textAlign: 'left' } }}>
                                    <h2 style={{ margin: 0 , height:"fit-content"}}>{val.name}</h2>
                                    <span style={{ color: 'GrayText', fontWeight: "bold" }}>  {("Sous-Total :" + val.price + "€").toLowerCase()}</span>
                                </Stack> */}

                  <Stack
                    spacing={0.5}
                    direction={"column"}
                    sx={{
                      lineHeightStep: "0.5px",
                      "& span": { textAlign: "left" },
                    }}
                  >
                    <h2
                      style={{
                        margin: 0,
                        height: "fit-content",
                        fontSize: "20px",
                        fontWeight: "900",
                        color: "black",
                        lineHeight: "1",
                      }}
                    >
                      {val.name}
                    </h2>
                    <span
                      style={{
                        color: "#6c757d",
                        fontWeight: "bold",
                        lineHeight: "1",
                      }}
                    >
                      {"Sous-Total :" + val.price + "€"}
                    </span>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    className="row all-center"
                    style={{
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Avatar style={{ width: "30px", height: "30px" }}>
                      {" "}
                      {val.orderedCategories?.length}{" "}
                    </Avatar>
                    <ChevronRightRoundedIcon />
                  </Stack>
                </Stack>

                {/* <Stack sx={{ "& p": { textAlign: 'left', paddingLeft: "10px" } }}>
                                <p >à emporté à : {val.adress ? val.adress : null} </p>
                                <p> isOpen {true ? 'Ouvert' : "Fermé . Ouvre à nextShift"}  </p>
                            </Stack> */}

                <div
                  style={{
                    textAlign: "left",
                    lineHeight: "1.6",
                    fontSize: "small",
                    fontWeight: "100",
                    color: "gray",
                  }}
                >
                  <p>
                    à emporter à:{" "}
                    {val.address ? val.address : "Adresse non spécifiée"}
                  </p>
                  <p>Statut: {true ? "Ouvert" : "Fermé. Ouvre à nextShift"}</p>
                </div>
              </Stack>
            </Button>
            <Divider />
          </Stack>
        );
      })}
    </Stack>
  );
};

const ArticleListView = (props: any) => {
  const {  items } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let categories = props.cartList[0]?.categories;

  const handleClick = () => {};
  const handleClose = () => {
    setAnchorEl(null);
  };

  let obj = props.cartList


//   let arrayOfQuantityArtical = props.newCartList[0].categories?.map((item:any)=>{return item?.quantity})
//   const quantityArtical = arrayOfQuantityArtical.reduce((accumulator:number, currentValue:number) => {
//     return accumulator + currentValue;
// }, 0);

  const allHasNoCompositions = (array: Array<any>) => {
    return array?.every((item: any) => !item.hasCompositions);
  };
 


  return (
    <Stack direction="column" spacing={1} className="articleViewContainer">
      {/* <Paper
        elevation={3}
        className="headerContainer"
        style={{ padding: "10px" }}
      >
        <Stack direction={"column"} spacing={1}>
          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div>
              <h2>{props.newCartList[0].restaurant?.nom?.toString()}</h2>
              <div>{props.shopMode === 0 ? "A emporter" : "Sur place"}</div>
            </div>

            <IconButton
              onClick={handleClick}

              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            ></Menu>
          </div>

          <div
            className="row"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              {quantityArtical +
                "  Article" +
                (props.newCartList[0].categories?.length > 1 ? "s" : "")}
            </span>
            <b>{"Sous-Total : " + props.currentPrice + "€"}</b>
          </div>
        </Stack>
        <div></div>
        <Divider />
      </Paper> */}

      <Divider sx={{ borderColor: " lightgray" }} />

      <div className="articleListContainer">
        {props.savedCategories[props.id]?.categories?.map((val: any, index: any) => {
          console.log("vaaaaaaaal",val)
          return (
            <Fragment key={index}>
              <Stack
                direction={"column"}
                key={index}
                className="articleContainer"
              >
                <h3
                  className=""
                  style={{
                    margin: "0",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="categoryTitleContainer row" style={{ display:"flex",  justifyContent:"space-between" ,alignItems:"center"}}>
                    <span>{val?.name} </span>
                    {/* <span id='topLeftCurve'></span> */}
                    <div style={{width:"50px",height:"50px" }}>
                    <img src={val?.image} alt=""  width="100%" style={{borderRadius:"16px"}}/>
                    </div>
                  </span>
                  <div style={{display:"flex",justifyContent:"flex-end"}}> 

                  <span className="priceContainer">{(val?.amount* val?.quantity).toFixed(2) + " €"}</span>
                  </div>
                </h3>

                <Stack
                  direction={"row"}
                  className="counterContainer row "
                  sx={{ alignItems: "center" }}
                >
                  {val?.quantity > 1 ? (
                    <IconButton onClick={()=>props.__handleChangeCount("DECREASE",index,null,val)}>
                      <RemoveCircleOutlineRoundedIcon />{" "}
                    </IconButton>
                  ) : (
                    <IconButton  onClick={()=>props.__handleChangeCount("DECREASE",index,null,val)}>
                      {" "}
                      <DeleteForeverRoundedIcon />{" "}
                    </IconButton>
                  )}

                  {val?.quantity > 9 ? val?.quantity : "0" + val?.quantity}

                  <IconButton  onClick={()=>props.__handleChangeCount("INCREASE",index,null,val)}>
                    <AddCircleOutlineRoundedIcon />
                  </IconButton>
                </Stack>

                <Stack className="productListContainer" spacing={2}>
                  {val?.products?.map((product: any, index: number) => {
                    return (
                      product.numberOfSelection >= 1 && (
                        <Fragment key={index}>
                          <Stack className="productContainer" spacing={1}>
                            <b className="full-width">
                              {product.name.toLowerCase()}
                            </b>

                            <Stack
                              className="componentListContainer"
                              direction={
                                allHasNoCompositions(product.components)
                                  ? "row"
                                  : "column"
                              }
                              spacing={1}
                              flexWrap="wrap"
                            >
                              {product.components?.map(
                                (component: any, index: number) =>
                                  component.numberOfSelection >= 1 && (
                                    <Stack
                                      className="componentContainer"
                                      spacing={1}
                                      key={index}
                                      flexWrap="wrap"
                                    >
                                      {component.hasCompositions ? (
                                        <b className="componentTitle">
                                          {component.name.toLowerCase()}
                                          {component.numberOfSelection > 1 ? (
                                            <Chip
                                              sx={{
                                                height: "20px",
                                                textTransform: "lowercase",
                                              }}
                                              size="small"
                                              color="secondary"
                                              label={
                                                "x".toLowerCase() +
                                                component.numberOfSelection
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </b>
                                      ) : (
                                        <Fragment>
                                          <Stack
                                            className="componentWithNotComposition"
                                            direction={"row"}
                                            key={index}
                                            spacing={0.7}
                                            flexWrap="wrap"
                                          >
                                            <span>
                                              {component.numberOfSelection >
                                              1 ? (
                                                <b style={{ color: "#212529" }}>
                                                  {" "}
                                                  {component.numberOfSelection +
                                                    "x".toLowerCase()}{" "}
                                                </b>
                                              ) : (
                                                ""
                                              )}{" "}
                                              {component.name.toLowerCase()}
                                            </span>

                                            {/* <Divider orientation='vertical' /> */}
                                          </Stack>
                                        </Fragment>
                                      )}

                                      {component.hasCompositions ? (
                                        <Fragment>
                                          <Stack
                                            direction={"row"}
                                            className="compositionListContainer"
                                            spacing={1}
                                            flexWrap="wrap"
                                          >
                                            {/* <ChecklistRoundedIcon color='action' /> */}
                                            {component.compositions.map(
                                              (
                                                composition: any,
                                                index: number
                                              ) => {
                                                return (
                                                  composition.numberOfSelection >
                                                    0 && (
                                                    <Fragment key={index}>
                                                      <Stack
                                                        className="compositionList"
                                                        direction={"row"}
                                                        key={index}
                                                        spacing={0.7}
                                                        flexWrap="wrap"
                                                      >
                                                        {/* <Divider orientation='vertical' /> */}
                                                        <span>
                                                          {composition.numberOfSelection >
                                                          1 ? (
                                                            <b
                                                              style={{
                                                                color:
                                                                  "#212529",
                                                              }}
                                                            >
                                                              {" "}
                                                              {composition.numberOfSelection +
                                                                "x".toLowerCase()}{" "}
                                                            </b>
                                                          ) : (
                                                            ""
                                                          )}{" "}
                                                          {composition.name.toLowerCase()}
                                                        </span>
                                                      </Stack>
                                                    </Fragment>
                                                  )
                                                );
                                              }
                                            )}
                                          </Stack>
                                          {/* <Divider orientation='horizontal' sx={{ border: "solid 1px whitesomke" }} /> */}
                                        </Fragment>
                                      ) : null}
                                    </Stack>
                                  )
                              )}
                            </Stack>
                          </Stack>
                        </Fragment>
                      )
                    );
                  })}
                </Stack>
              </Stack>
              <Divider
                sx={{ backgroundColor: "gray", border: "solid 2px lightgray" }}
              />
            </Fragment>
          );
        })}
      </div>
      <Button
        variant="contained"
        onClick={() => {
          props.navigate("/payment");
          props.close();
        }}
      >
        Commander
      </Button>
    </Stack>
  );
};


function ClippedDrawer(props: any) {
  console.log(props?.URLParams?.id)
  console.log("zdazzzzzzz",props)
  const [anchor, setAnchor] = useState();
  const [totalQuantity, settotalQuantity] = useState(0)
  const [cartList, setcartList] = useState([])
  let id = props.URLParams.id
  let cats = props.savedCategories[id]?.categories
  console.log("cats",cats)
  const [selectedCategories, setselectedCategories] = useState<serviceMenuModels.Category[]>(cats)
  const baseUrl1 = config.api_baseUrl;
  
  // React.useEffect(() => {
  //   const fetchRestaurantById = () =>{
  //     axios.get(baseUrl1 + "/restaurants/" + props?.URLParams?.id)
  //   }
    
  // }, [])

  // const cart: any = cartExample;
  // console.log(cartExample)
  // console.log(cart)
  // const [currentIndex, setCurrentIndex] = useState<any>(
  //   cart.restaurants?.length > 1 ? -1 : 0
  // );
  type props = {
    restaurant: serviceMenuModels.Restaurant
    mode: number,
    categories: serviceMenuModels.Category[],
    formOrderHistory?: boolean
}
function calculatePriceForUnpairedQuantity(quantity: number, pricePerUnit: number) {
  if (quantity % 2 === 0) {
      const pairedQuantity = Math.floor(quantity / 2);
      return pricePerUnit * pairedQuantity;
  } else {
      // Quantity is odd, calculate price for unpaired item
      const unpairedQuantity = Math.floor(quantity / 2) + 1;
      const totalPrice = unpairedQuantity * pricePerUnit;
      return totalPrice;

  }
}
  
  const cartMapper = async (cart:any,id:any) => {

   let respons = await shopApi.getResturantById({
    id: id,
    diningMode: cart.mode,
    adresse: {
      latitude: 0,
      longitude: 0,
    },
   })
   
    let totalPrice = 0
    let count = 0
    await Promise.all(cart?.categories?.map((cat:any, i:any) => {
        totalPrice =  totalPrice + (cat?.amount * cat?.quantity)
        count = count + cat?.quantity
    }))
    return {
        title: respons?.data?.restaurant?.nom,
        count: count,
        restaurant: respons?.data?.restaurant,
        price: totalPrice,
        mode: cart.mode,
        menuId: 0,
        categories: cart.categories




    }
    // let TopMenuList = restaurant.menus?.map((menu,index)=>menu.name)
    //



}

  const __updateOrder = (i: number, type: string, index: number, categoryId: number, menuId: number, selectedCategory: serviceMenuModels.Category, deleteRestaurant?: boolean,) => {
  
    
    let curreSavedCart = props.savedCategories
    console.log("curreSavedCart",curreSavedCart)
    let indexOfCategory = i

    let category: serviceMenuModels.Category = curreSavedCart[id]?.categories[indexOfCategory]

    console.log("category",category)
    if (type == "INCREASE") {
        //  let categories = curreSavedCart[index].menu.menus[menuId].categories?.filter((cat: serviceMenuModels.Category) => cat.id == categoryId)
        category.quantity = category?.quantity + 1
        //   category.amount = category.amount + category.price
       curreSavedCart[id].categories[indexOfCategory] = category
        props.updateOrder(curreSavedCart)
    }
    else if (type == "DECREASE") {


        // let categories = curreSavedCart[index].menu.menus[menuId].categories?.filter((cat: serviceMenuModels.Category) => cat.id == categoryId)
        //let indexOfCategory = selectedCategory
        //    let category: serviceMenuModels.Category = curreSavedCart[index].menu.menus[menuId].categories[indexOfCategory]
        if (category?.quantity - 1 > 0) {
          console.log("caledddddd111")
            //let category: serviceMenuModels.Category = curreSavedCart[id]?.categories[indexOfCategory]
   
            category.quantity = category?.quantity - 1
  
            //   category.amount = category.amount + category.price
            curreSavedCart[id].categories[indexOfCategory] = category
   
            props.updateOrder(curreSavedCart)
        }
  
        else {
          console.log("caledddddd222",curreSavedCart[id])
            if (deleteRestaurant) {
              console.log("caledddddd333",curreSavedCart[index])
      
 
                //delete curreSavedCart[id]

                 //props.navigate(-1)
            }
            else if (category.quantity - 1 <= 0) {
              // console.log("caledddddd444")
              //   let cats = curreSavedCart[id]?.categories
        
              //   cats?.splice(indexOfCategory, 1)
              //   curreSavedCart[id].categories[index] = cats
          
              //   let selectedCategories = curreSavedCart[id]?.categories[index]
  
              //   if (selectedCategories.length == 0)
          
              //       delete curreSavedCart[id]?.categories[index]

              let updatedCategories = curreSavedCart[id].categories.filter((cat:any, idx:any) => idx !== indexOfCategory);
              curreSavedCart[id].categories = updatedCategories;
                props.updateOrder(curreSavedCart);
            }
        }
        props.updateOrder(curreSavedCart)
    }
  }


 
  
  const __handleChangeCount = (type: string, i: number, menuId: number, selectedCategory: serviceMenuModels.Category) => {
    let selecteds = selectedCategories;
    console.log("selecteds", selecteds);
  
    if (type === 'INCREASE' && selecteds) {
   
      settotalQuantity(totalQuantity + 1);
      __updateOrder(i, 'INCREASE', i, selecteds[i]?.id, selecteds[i]?.menuId, selectedCategory);
    } else if (type === 'DECREASE' && selecteds) {
      settotalQuantity(totalQuantity - 1);
  
      if (selecteds[i]?.quantity - 1 < 1) {
        __updateOrder(i, 'DECREASE', i, selecteds[i]?.id, selecteds[i]?.menuId, selectedCategory, (totalQuantity - 1) == 0);
  
        let index = selecteds?.findIndex((el) => el?.id == selectedCategory?.id);
  
        if (index !== undefined && index !== -1) {
          selecteds.splice(index, 1);
          setselectedCategories([...selecteds]);
        }
      } else {
        __updateOrder(i, 'DECREASE', i, selecteds[i]?.id, selecteds[i]?.menuId, selectedCategory, (totalQuantity - 1) == 0);
      }
    }
  };
  
 
const __mapCart = () => {
  let cartItems:any = []

  Promise.all(Object.keys(props.savedCategories).map(async (el, i) => {
    let id =el

    console.log(el)
      let cartItem = props.savedCategories[el]
  
      let mappedCart = await cartMapper(cartItem,id)
  
      cartItems.push({
          ...mappedCart,
          shownAddress: props.location.shownAddress,
          restaurantId: el
          
      })

     // setselectedCategories(mappedCart.categories)
      // if (props?.restaurantIdRedirection != null && props?.restaurantIdRedirection > -1) {
      //     let item = cartItems?.find((cart:any) => cart?.restaurant?.id === restaurantId);
      //    await props.selectOrder(item);
      //     navigate("Orders", null, false)

      // }


  }))
  setcartList(cartItems)
  props.updateCartres(cartItems)
}


React.useEffect(() => {
  if (props.savedCategories) {
    __mapCart()
}
}, [props.savedCategories])

  return (
    <>
      <Drawer
        anchor={"right"}
        open={props.open}
        onClose={() => props.setOpen(false)}
        variant="temporary"
        sx={{
          width: { xs: 330, sm: drawerWidth },
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: { xs: 330, sm: drawerWidth },
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />

        <Box
          sx={{
            overflow: "auto",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            textTransform: "capitalize",
          }}
        >
          {
            // currentIndex === -1 ?
            false ? (
              <RestaurantListView
                // array={cart.restaurants}
                // indexState={[currentIndex, setCurrentIndex]}
              />
            ) : (
              <ArticleListView
              cartList={cartList}
              id={id}
              newCartList = {props.newCartList}
              savedCategories= {props.savedCategories}
              __handleChangeCount={__handleChangeCount}
                close={() => props.setOpen(false)}
                navigate={props.navigate}
                shopMode={props.shopMode}
                // obj={cartList[0].categories}
                // indexState={[currentIndex, setCurrentIndex]}
                items={Object.values(props.savedCategories)[0]}
                currentPrice={props.currentPrice}
              />
            )
          }
        </Box>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    selectedOrder: state.order.selectedOrder,
    savedCategories: state.cart.savedCategories,
    currentPrice: state.cart.currentPrice,
    shopMode: state.shop.mode,
    cartList:state.order.cartList
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateOrder: (obj: any) => dispatch(updateOrder(obj)),
    updateCurrentPrice: (newPrice: number, actionType: string) =>
    dispatch(updateCurrentPrice(newPrice, actionType)),
    updateCartres: (cartlist: any) =>
    dispatch(updateCartList(cartlist)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(ClippedDrawer));
