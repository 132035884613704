import { savedCartData } from "../../data/savedCategorieData";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  cart: null,
  currentPrice: 0,
  preSavedCart: {},
  categoryBasePrice: null,
  compositionsPrice: -1,
  categoryCount: 1,
  savedCart: {},
  priceChangeCount: 0,
  catId: null,
  savedCategories: {},
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_CART:
      return {
        ...state,
        cart: action.obj,
      };

    case actionTypes.PRE_SAVE_CART:
      return {
        ...state,
        preSavedCart: action.preSavedCart,
      };

    case actionTypes.SELECT_CATEGORY:
      return {
        ...state,
        catId: action.category.id,
        //  currentPrice: state.currentPrice == 0 ? action.initialEditPrice ? (action.initialEditPrice) : action.category.price : state.currentPrice,
      };

    case actionTypes.SAVE_CART: {
      return {
        ...state,
        savedCart: { ...action.obj },
      };
    }

    case actionTypes.RESET_CATEGORY:
      return {
        ...state,
        currentPrice: 0,
        categoryCount: 1,
      };

    case actionTypes.UPDATE_COMPOSITION_PRICE: {
      let type = action.actionType;
      let currentPrice = state.compositionsPrice;

      if (type == "INCREASE") {
        currentPrice = action.newPrice;
      } else if (type == "DECREASE") {
        if (currentPrice >= 0) currentPrice = currentPrice - action.newPrice;
      }

      return {
        ...state,
        compositionsPrice: currentPrice,
        priceChangeCount: state.priceChangeCount + 1,
      };
    }

    case actionTypes.UPDATE_CURRENT_PRICE: {
      let type = action.actionType;
      let currentPrice = state.currentPrice;

      if (type == "INCREASE") {
        currentPrice = currentPrice + action.newPrice;
      } else if (type == "DECREASE") {
        currentPrice = currentPrice - action.newPrice;
      }

      return {
        ...state,
        currentPrice: currentPrice,
        priceChangeCount: state.priceChangeCount + 1,
      };
    }

    case actionTypes.UPDATE_CATEGORY_COUNT: {
      let type = action.typeOfAction;
      let currentCount = state.categoryCount;

      if (type == "INCREASE") {
        currentCount = currentCount + 1;
      } else if (type == "DECREASE") {
        currentCount = currentCount - 1;
      }

      return {
        ...state,
        categoryCount: currentCount,
      };
    }
    case actionTypes.UPDATE_ORDER:
      return {
        ...state,
        savedCategories: { ...action.obj },
      };

    case actionTypes.UPDATE_SAVED_CART:
      return {
        ...state,
        savedCategories: { ...action.obj },
      };

    case actionTypes.PUSH_CATEGORY:
      return {
        ...state,
        savedCategories: { ...action.category },
      };
    case actionTypes.CLEAR_STORE_ITEM: {
      var key = action?.item;
      var obj: any = {};
      obj[key] = {};

      return {
        ...state,
        ...obj,
      };
    }
    default:
      return state;
  }
}

export default reducer;
