import React from "react";
import ListSubMenuElement from "./ListSubMenuElement";

export default function SubMenuElement({compo}:{compo:serviceDetailsOrderModels.Component}) {
  return (
    <div className="SubMenuElement">
      <ListSubMenuElement compo={compo} />
    </div>
  );
}
