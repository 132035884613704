import React from "react";
import { findCurrentShift } from "../../../utils/shopSubtittleFormat";
import moment from "moment";
export default function CartHeader(props: any) {
  
  const currentShift = findCurrentShift(props.category?.restaurant);

  return (
    <div className="CartHeader">
      {props?.category?.categories?.length === 0 ? (
        "No category"
      ) : (
        <>
          <div className="wrapper">
            <p className="restaurantName">{props.category?.restaurant?.nom}</p>
            <p className="restaurant-status">
               {currentShift?.isCurrentShift ? "Fermé" : "Ouvre"} à{" "}
              {currentShift?.isCurrentShift
                ? moment(currentShift?.close, "HH:mm").format("HH:mm")
                : moment(currentShift?.open, "HH:mm").format("HH:mm")}{" "}
              •  
            </p>
            <div className="col-wrapper">
              <p className="restaurantAdresse">
                {props.category?.mode == 0 ? "A Emporter" : "Sur Place"} :
                {props.category?.restaurant?.address}
              </p>
              <p className="articlesSlot">
                {props.category?.categories?.length} article
                {props.category?.categories?.length > 1 ? "(s)" : ""}
              </p>
            </div>
          </div>
          <div className="seperator-cart"></div>
        </>
      )}
    </div>
  );
}
