import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { shopApi } from "../../services/apiGate";
import { getAllCategoriesSuccess, getCategoryShopsListSuccess, updateCategoriesLoading } from "../actions/actions";

//* get all categories  
export function* getAllCategoriesSaga(action: serviceCategoryModels.categoryAction) {
    try {
        yield put(updateCategoriesLoading(true))
        let response: AxiosResponse = yield shopApi.getCategoriesList()

        if (response.data) {
            let data: serviceCategoryModels.getAllCategoriesResponse = response.data

            yield put(updateCategoriesLoading(false))
            yield put(getAllCategoriesSuccess(data))
        }
    } catch (error: any) {
        yield put(updateCategoriesLoading(false))
    }
}

export function* getShopCategoryListSaga(action: serviceShopModels.getFiltredShopBodyAction) {
    try {
        let response: AxiosResponse = yield shopApi.getFiltredShop(action.body)

        if (response.data) {
            let data: serviceShopModels.getFiltredShopResponse = response.data
           
            yield put(getCategoryShopsListSuccess(data))
        }
    } catch (error: any) {
    }
}