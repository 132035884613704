import * as actionTypes from "../actions/actionTypes";

const initialState = {
  shops: [],
  homeLoading: false,
  mode: 0,
  favoriteRestaurants: null,
  restaurantNameFilter: "",
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_FILTRED_SHOP_SUCCESS: {
      return {
        ...state,
        shops: action.shops,
      };
    }

    case actionTypes.UPDATE_HOME_LOADING: {
      return {
        ...state,
        homeLoading: action.value,
      };
    }

    case actionTypes.CLEAR_STORE_ITEM: {
      var key = action?.item;
      var obj: any = {};
      obj[key] = null;

      return {
        ...state,
        ...obj,
      };
    }
    case actionTypes.CHANGE_SHOPS_MODE: {
      return {
        ...state,
        mode: action.value,
      };
    }

    case actionTypes.GET_FILTRED_SHOP_SUCCESS: {
      return {
        ...state,
        favoriteRestaurants: action.restaurants,
      };
    }

    case actionTypes.CHANGE_FILTER_RESTAURANTS_INPUT: {
      return {
        ...state,
        restaurantNameFilter: action.value,
      };
    }

    default:
      return state;
  }
}

export default reducer;
