import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CloseIcon from "../../../images/CloseIcon_btn.svg";
import Bin from "../../../images/red_bin.svg";
import EmptyCart from "../../../images/empty-cartIcon.svg";
import { useLocation } from "react-router-dom";
import CartHeader from "./CartHeader";
import CartElements from "./CartElements";
import "./cart.scss";
import { pushCategory } from "../../../store/actions/actions";
export const CartData = (props: any) => {
  // const showPanier = () => {
  //   alert("xx");
  // };
  const location = useLocation();
  const [showEmptyPanier, setShowEmptyPanier] = useState(false);
  const [savedCat, setSavedCat] = useState<any>(props.savedCategories);
  console.log("objectsavedCat", props);
  console.log("objectsavedCat", parseInt(location?.pathname?.split("/")[2]));
  const [isRedirected, setIsRedirected] = useState(false);
  useEffect(() => {
    setSavedCat(props.savedCategories);
  }, [savedCat]);

  // useEffect(() => {
  //   if (props.category?.categories === undefined || props.category?.categories.length === 0) {
  //     localStorage.setItem("redirectedToPrevPage", "false");
  //     setIsRedirected(true); // Set isRedirected to true when categories are empty or undefined
  //   } else {
  //     localStorage.setItem("redirectedToPrevPage", "true");
  //     setIsRedirected(false); // Set isRedirected to false when categories exist
  //   }
  // }, [props.category?.categories]);

  console.log("isRedirected", isRedirected);
  const handleDeleteCommand = () => {
    // Make a copy of savedCat to avoid mutating state directly
    const data = delete savedCat[parseInt(location?.pathname?.split("/")[2])];
    props.pushCategory(data);
    props.setShowCommandDetails(!props.ShowCommandDetails);
  };
  const CloseModal = () => {
    setShowEmptyPanier(!showEmptyPanier);
    document
      .querySelector(".CommandInfo")
      ?.classList.remove(".showCommandDetails");
  };

  const [blockBody, setblockBody] = useState(false);

  // useEffect(() => {
  //   if (blockBody) {
  //     document.querySelector("body")?.classList.add("disable-scroll");
  //   } else {
  //     document.querySelector("body")?.classList.remove("disable-scroll");
  //   }
  // }, [blockBody]);
  const handelBodyScroll = () => {
    setblockBody((prevblockBody) => !prevblockBody);
  };
  return (
    <div
      className={`CartData CartElementSection ${
        props.category?.categories === undefined ||
        props.category?.categories?.length === 0
          ? "noItems"
          : ""
      }`}
    >
      <div className="closeBtn">
        <img
          className=""
          src={CloseIcon}
          alt="CloseIcon"
          onClick={() => {
            CloseModal();
            props.setShowCommandDetails(!props.ShowCommandDetails);
            props.handleCloseIconExit()
            handelBodyScroll();
            //props.handleCloseIcon();
          }}
        />
      </div>
      {props.category?.categories === undefined ||
      props.category?.categories?.length === 0 ? (
        <div className="panier-wrapper">
          <img className="EmptyCart" src={EmptyCart} alt="EmptyCart" />
          <p>Aucun Article ajouté</p>
          <span>Ajoutez des articles, ils seront affichés ici.</span>
        </div>
      ) : (
        <>
          <div className="CartComponents">
            <CartHeader category={props.category} location={props.location} />
            <CartElements
              category={props.category}
              idUsed={props.idUsed}
              restaurantID={props.restaurantID}
              setShowCommandDetails={props.setShowCommandDetails}
            />
            {/* <div className="cancel-btn">
              <button className="cancel-btn" onClick={handleDeleteCommand}>
                <img src={Bin} alt="" />
                Annuler commande
              </button>
            </div> */}
          </div>
          <div className="CartFooter">
            <button
              className="btn-command"
              onClick={() => {
                console.log("props.restaurantID?.id", props);
                localStorage.setItem(
                  "resturantId",
                  props.category?.restaurant?.id
                );
                props.handleCommand(props.category?.restaurant?.id);
              }}
            >
              Commander • {props.totalAmount}€
            </button>
            <button
              onClick={props.navigateToShope}
              className="btn-add-articles"
            >
              Ajouter article(s)
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  location: state.auth.location,
  savedCategories: state.cart.savedCategories,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartData);
