

export const AppConfigProd = {
    api_timeout: 5000,
    app_version: "0.0.1",
    mode: "prod",
    url:'https://fioeat.com/',

    api_baseUrl: 'https://fioeatbackendprod.azurewebsites.net',
    api_baseUrl_Picture: "https://fioeatpublicfolder.frenchinnov.fr/uploads/",
    key_google: "AIzaSyBV9I7IJhaLGsNn4C4yjTwzTgdtlYpIZJU",
    success_timout_animation: 700,
    mapObject: {
        key: "AIzaSyBV9I7IJhaLGsNn4C4yjTwzTgdtlYpIZJU",

        language: 'fr',
        components: 'country:fr',
        type:"address"
    },
    stripe_public_key: "pk_live_51ONHJ6AAtUhMBXbbayMmTISMCFb9YcgULgwxVcgXSeSLil6ROF0FSvnSD0gQJ2BiNNQ1cZXNpdn8OJGA9BwQRJox00e6RxcSo4",
}