import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { useEffect, useState, useCallback, Fragment } from "react";
import CloseIconBtn from "../../images/CloseIcon_btn.svg";
import { connect } from "react-redux";
import "./style.scss";
import {
  preSaveCart,
  updateCompositionsPrice,
  updateCurrentCategory,
  updateCurrentPrice,
} from "../../store/actions/actions";
import _, { cloneDeep } from "lodash";
const QuantityCounter = (props: any) => {
  const {
    maxSelection,
    maxCmpSelectionState,
    totalCount,
    setTotalCount,
    setSingleCount,
    index,
    item,
    quantityArray,
    currentPrice,
    updateQuantityArray,
    globalMaxSelection,
    price,
    updateCurrentPrice,
    updateCompositionsPrice,
  } = props;

  const [product, setProduct] = useState<serviceMenuModels.Product>(
    props.product
  );

  console.log("totalCount", props.item);
  console.log("totalCount", totalCount);

  const [count, setCount] = useState<number>(
    item.numberOfSelection ? item.numberOfSelection : 0
  );
  console.log("objectcount", count);
  const handleQuantityInDecrise = () => {
    const newCount = count - 1;
    //setTotalCount(totalCount - 1);
    if (newCount >= 0) {
      setCount(newCount);
      setTotalCount(totalCount - 1);
      setSingleCount(totalCount - 1);
      if (newCount === 0) {
        setSingleCount(0); // Reset singleCount when count becomes 0
      }
      props.updateProductSelection("DECREASE", props.indexOfComponent, props.i); // Call updateProductSelection to decrease quantity
    }
  };

  const handleQuantityInCrease = () => {
    if (count < maxSelection) {
      const newCount = count + 1;
      setCount(newCount);
      setTotalCount(totalCount + 1);
      setSingleCount(totalCount + 1);
      props.updateProductSelection("INCREASE", props.indexOfComponent, props.i); // Call updateProductSelection to increase quantity
    }
  };

  return (
    <div
      className="multiselectionCounter row"
      style={{
        fontSize: "14px",
        fontWeight: "bold",
        alignItems: "center",
        color: "GrayText",
      }}
    >
      <div>
        {/* <span style={{ paddingRight: "3px" }}>
          {item?.price > 0 ? item?.price : null}
        </span> */}

        <span
          style={{
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            color: `${props.count === maxSelection ? "#C7C8CC" : " "}`,
          }}
        >
          {item?.name}
          {/* <p>{item.numberOfSelection}</p> */}
          <span className="price-el">
            {item?.price > 0 ? "+" : ""} {item?.price > 0 ? item?.price : null}{" "}
            {item?.price > 0 ? "€" : ""}
          </span>
        </span>
      </div>
      <div className="multiselectionCounterQyt">
        <span
          style={{
            width: count > 0 ? "50px" : "0px",
            overflowX: "hidden",
            transitionDuration: "700ms ",
          }}
          className="row all-center"
        >
          <IconButton
            size="small"
            disabled={count === 0}
            onClick={handleQuantityInDecrise}
            //   disabled={quantityArray?.[index] === 0}
            //   onClick={() => {
            //     if (quantityArray[index] > 0) {
            //       updateQuantityArray(quantityArray[index] - 1, index);
            //       updateCurrentPrice(price, "DECREASE");
            //     }
            //   }}
            className={count === 0 ? "hidden" : ""}
          >
            <RemoveCircleOutlineRoundedIcon />
          </IconButton>
          {/*  eslint-disable-next-line @typescript-eslint/no-unused-expressions*/}
          {/* {quantityArray[index] > 9
          ? quantityArray[index]
          : "0" + quantityArray[index]} */}
          <span className="count-number"> {count} </span>
        </span>

        <IconButton
          size="small"
          disabled={
            (maxCmpSelectionState === 0 && count <= maxSelection)
              ? false
              : totalCount >= maxCmpSelectionState || count >= maxSelection
          }
          onClick={handleQuantityInCrease}

          // disabled={
          //   quantityArray[index] === maxSelection ||
          //   globalCount() === globalMaxSelection
          // }
          // onClick={() => {
          //   if (quantityArray[index] < maxSelection) {
          //     updateQuantityArray(quantityArray[index] + 1, index);
          //     updateCurrentPrice(price, "INCREASE");
          //   }
          // }}
        >
          <AddCircleOutlineRoundedIcon fontSize="medium" />
        </IconButton>
      </div>
      {/* <p>amount :{item?.price * props.singleCount}</p> */}
    </div>
  );
};

function Composition(props: any) {
  const {
    item,
    maxCmpSelectionState,
    updateProductSelection,
    compositionQunatity,
    setSingleCount,
    singleCount,
    totalCount,
    setTotalCount,
  } = props;

  const [product, setProduct] = useState<serviceMenuModels.Product>(
    props.product
  );

  const [selected, setSelected] = useState<boolean>(() => {
    const composition = props.item;

    if (composition && composition.defaultHidden) {
      return false;
    }
    return (
      (composition && composition.numberOfSelection > 0) ||
      composition.isDefault
    );
  });

  console.log("objectselected", item.numberOfSelection);

  const [checkboxCount, setCheckboxCount] = useState<number>(0);
  const [interacted, setInteracted] = useState<boolean>(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    console.log("isChecked", isChecked);
    setSelected(isChecked);
    setInteracted(true);
    console.log("objecttotalCount", totalCount);
    // Update the count of checked checkboxes
    if (isChecked && !item?.isDefault) {
      setCheckboxCount(checkboxCount + 1);
      setTotalCount(totalCount + 1);
      setSingleCount(totalCount + 1);
    } else if (!isChecked && !item?.isDefault) {
      setCheckboxCount(checkboxCount - 1);
      setTotalCount(totalCount - 1);
      setSingleCount(totalCount - 1);
    }

    // Call the updateProductSelection function based on checkbox state and isDefault value

    if (isChecked) {
      console.log("checkbox ckkk");
      updateProductSelection("INCREASE", props.indexOfComponent, props.i);
    } else {
      updateProductSelection("DECREASE", props.indexOfComponent, props.i);
    }
  };

  return (
    <FormControlLabel
      className="ElementComposition"
      label={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span>{props.item?.name.toLowerCase()}</span>
            <span className="price-el">
              {props.item?.price > 0 ? "+" : ""}{" "}
              {props.item?.price > 0 ? props.item?.price : null}{" "}
              {/* <p>{props.item.numberOfSelection}</p> */}
              {props.item?.price > 0 ? "€" : ""}
            </span>
          </div>
        </div>
      }
      control={
        <Checkbox
          checked={selected}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
          // disabled={
          //   !interacted &&
          //   !selected &&
          //   (totalCount < 0 ||
          //     totalCount >= maxCmpSelectionState ||
          //     checkboxCount >= maxCmpSelectionState)
          // }
          disabled={
            maxCmpSelectionState === 0
              ? false
              : !selected &&
                totalCount >= maxCmpSelectionState &&
                !item?.isDefault
          }
        />
      }
    />
  );
}

function CompositionDialog(props: any) {
  const { open, setOpen } = props.openState;

  const initProduct = props.obj;
  const [product, setproduct] = useState<serviceMenuModels.Product>(props.obj);
  const [maxCmpSelectionState, setMaxCmpSelectionState] = useState(
    props.component?.maxCmpSelection
  );
  const components = product?.components?.filter(
    (comp, ind) => ind === props?.componentId
  );
  const component = components[0];
console.log("component",component)
  const [compositionsTotalPrice, setcompositionsTotalPrice] = useState<number>(
    component?.compositionsTotalPrice === undefined
      ? 0
      : component?.compositionsTotalPrice
  );

  const [compositionQunatity, setcompositionQunatity] = useState<number>(
    component?.compositionSelection === undefined
      ? 0
      : component?.compositionSelection
  );

  const [totalCount, setTotalCount] = useState<number>(
    component?.compositionSelection === undefined
      ? 0
      : component?.compositionSelection
  );

  const [compositions, setcompositions] = useState<
    serviceMenuModels.Composition[]
  >(_.cloneDeep(props.component?.compositions));
  const [singleCount, setSingleCount] = useState(0);

  console.log("compositions", compositions);

  const changeCategory = (changedProduct: serviceMenuModels.Product) => {
    try {
      let category: serviceMenuModels.Category = props.selectedCategory;
      let restaurant: serviceMenuModels.Restaurant = props.menu;

      if (category?.products)
        category.products[props.productHasCompositionId] = changedProduct;

      category = {
        ...category,
        selected: true,
      };

      props.preSaveCart({
        category: category,
        restaurant: restaurant,
        mode: props.mode,
        menuId: props.menuId,
      });

      props.updateCurrentCategory(category, props.menuId);
      //    updateCart({ ...category });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    //setcompositions(props.obj?.components[props.componentId].compositions);
    setcompositions(props.obj?.components[props.componentId].compositions);
    setcompositionQunatity(component?.compositionSelection);
    __makeChangeInCurrentCategory(initProduct, props.index);
    setcompositionsTotalPrice(component?.compositionsTotalPrice);
  };

  function updateProductSelection(
    action: "INCREASE" | "DECREASE",
    indexOfComponent: number,
    indexOfComposition: number
  ) {
    // try {
    //   let compositionsLocal = _.cloneDeep(compositions);
    //   if (action === "INCREASE") {
    //     // Increase the number of selection for the component at indexOfComponent
    //     if (compositionsLocal) {
    //       //  updatedProduct.components[indexOfComponent].numberOfSelection += 1;
    //       // Increase the number of selection for the composition at indexOfComposition
    //       if (compositionsLocal[indexOfComposition]) {
    //         if (
    //           !compositionsLocal[indexOfComposition].isDefault &&
    //           compositionsLocal[indexOfComposition].price > 0
    //         ) {
    //           setcompositionsTotalPrice(
    //             compositionsTotalPrice +
    //               compositionsLocal[indexOfComposition].price
    //           );
    //         }
    //         if (
    //           compositionsLocal[indexOfComposition].isDefault &&
    //           compositionsLocal[indexOfComposition].defaultHidden
    //         ) {
    //           compositionsLocal[indexOfComposition].defaultHidden = false;
    //         }
    //         if (
    //           compositionsLocal[indexOfComposition].isDefault &&
    //           compositionsLocal[indexOfComposition].defaultHidden == undefined
    //         ) {
    //           compositionsLocal[indexOfComposition].defaultHidden = false;
    //         }
    //         if (!compositionsLocal[indexOfComposition].isDefault) {
    //           setcompositionQunatity(compositionQunatity + 1);

    //           compositionsLocal[indexOfComposition].numberOfSelection ==
    //           undefined
    //             ? (compositionsLocal[indexOfComposition].numberOfSelection = 1)
    //             : (compositionsLocal[indexOfComposition].numberOfSelection +=
    //                 1);
    //         }
    //       }
    //     }
    //   } else if (action === "DECREASE") {
    //     if (compositionsLocal) {
    //       if (compositionsLocal[indexOfComposition]) {
    //         if (compositionsLocal[indexOfComposition].isDefault) {
    //           compositionsLocal[indexOfComposition] = {
    //             ...compositionsLocal[indexOfComposition],
    //             defaultHidden: true,
    //           };

    //           compositionsLocal[indexOfComposition].numberOfSelection = 0;
    //         } else {
    //           console.log("errorX12");
    //           if (
    //             !compositionsLocal[indexOfComposition].isDefault &&
    //             compositionsLocal[indexOfComposition].price > 0
    //           ) {
    //             setcompositionsTotalPrice(
    //               compositionsTotalPrice -
    //                 compositionsLocal[indexOfComposition].price
    //             );
    //           }
    //           if (!compositionsLocal[indexOfComposition].isDefault) {
    //             compositionsLocal[indexOfComposition].numberOfSelection =
    //               compositions[indexOfComposition].numberOfSelection ==
    //               undefined
    //                 ? 1
    //                 : (compositions[indexOfComposition].numberOfSelection -= 1);
    //             setcompositionQunatity(compositionQunatity - 1);
    //           }
    //         }
    //       }
    //     }
    //   }
    //   setcompositions(compositionsLocal);
    // } catch (error) {
    //   console.log("errorX", error);
    // }

    try {
      let compositionsLocal = _.cloneDeep(compositions);
      //console.log("object compositionsLocal", compositionsLocal[indexOfComposition])
      if (action === "INCREASE") {
        // Increase the number of selection for the component at indexOfComponent
        if (compositionsLocal) {
          //  updatedProduct.components[indexOfComponent].numberOfSelection += 1;
          // Increase the number of selection for the composition at indexOfComposition
          if (compositionsLocal[indexOfComposition]) {
            console.log(
              "object compositionsLocal",
              compositionsLocal[indexOfComposition]
            );
            if (
              !compositionsLocal[indexOfComposition].isDefault &&
              compositionsLocal[indexOfComposition].price > 0
            ) {
              setcompositionsTotalPrice(
                compositionsTotalPrice +
                  compositionsLocal[indexOfComposition].price
              );
              console.log(
                "defaultHidden",
                compositionsLocal[indexOfComposition]
              );
            }
            if (
              compositionsLocal[indexOfComposition].isDefault &&
              compositionsLocal[indexOfComposition]?.defaultHidden
            ) {
              console.log(
                "object compositionsLocal",
                compositionsLocal[indexOfComposition]
              );
              compositionsLocal[indexOfComposition].defaultHidden = false;
            }
            if (
              compositionsLocal[indexOfComposition].isDefault &&
              compositionsLocal[indexOfComposition].defaultHidden == undefined
            ) {
              console.log(
                "object compositionsLocal",
                compositionsLocal[indexOfComposition]
              );
              compositionsLocal[indexOfComposition].defaultHidden = false;
            }
            if (!compositionsLocal[indexOfComposition].isDefault) {
              setcompositionQunatity(compositionQunatity + 1);

              compositionsLocal[indexOfComposition].numberOfSelection ==
              undefined
                ? (compositionsLocal[indexOfComposition].numberOfSelection = 1)
                : (compositionsLocal[indexOfComposition].numberOfSelection +=
                    1);
            }
          }
        }
      } else if (action === "DECREASE") {
        if (compositionsLocal) {
          if (compositionsLocal[indexOfComposition]) {
            if (compositionsLocal[indexOfComposition].isDefault) {
              compositionsLocal[indexOfComposition] = {
                ...compositionsLocal[indexOfComposition],
                defaultHidden: true,
              };
              console.log(
                "conpostionaaaaaa",
                compositionsLocal[indexOfComposition].defaultHidden
              );
              console.log(
                "object compositionsLocal",
                compositionsLocal[indexOfComposition]
              );
              compositionsLocal[indexOfComposition].numberOfSelection = 0;
            } else {
              console.log("errorX12");
              if (
                !compositionsLocal[indexOfComposition].isDefault &&
                compositionsLocal[indexOfComposition].price > 0
              ) {
                setcompositionsTotalPrice(
                  compositionsTotalPrice -
                    compositionsLocal[indexOfComposition].price
                );
              }
              if (!compositionsLocal[indexOfComposition].isDefault) {
                compositionsLocal[indexOfComposition].numberOfSelection =
                  compositions[indexOfComposition].numberOfSelection ==
                  undefined
                    ? 1
                    : (compositions[indexOfComposition].numberOfSelection -= 1);
                setcompositionQunatity(compositionQunatity - 1);
              }
            }
          }
        }
      }

      setcompositions(compositionsLocal);
    } catch (error) {
      console.log("errorX", error);
    }
  }

  const __makeChangeInCurrentCategory = (
    changedProduct: serviceMenuModels.Product,
    productId: number
  ) => {
    try {
      let category: serviceMenuModels.Category = props.selectedCategory;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      category.products
        ? (category.products[productId] = changedProduct)
        : null;
      category.selected = true;
      let restaurant: serviceMenuModels.Restaurant;
      restaurant = props.menu;

      props.preSaveCart({
        category: category,
        restaurant: restaurant,
        mode: props.mode,
        menuId: props.menuId,
      });
    } catch (error) {
      console.log("RUNNING ERROR");
    }

    //  props.updateCurrentCategory({ ...category }, props.menuId)
  };

  type renderCategoryProduct = {
    item: serviceMenuModels.Composition;
    index: number;
  };

  const handleSubmit = async () => {
    try {
      let index = product?.components.indexOf(component);
      let p = product;

      let compo = p.components[index];

      if (p.maxSelection == 1) {
        p.components?.map((c) => {
          if (c.numberOfSelection > 0) {
            console.log("c.numberOfSelection", c);
            props.updateCurrentPrice(
              (c.compositionsTotalPrice ?? 0) + c.price,
              "DECREASE"
            );
          }
          if (c.hasCompositions && c.id != compo.id) {
            c.compositionSelection = 0;
            c.compositionsTotalPrice = 0;
            c.compositions.map((cmp) =>
              cmp.isDefault
                ? (cmp.defaultHidden = false)
                : (cmp.numberOfSelection = 0)
            );
          }
        });
      }
      p.components?.map((c) =>
        c.hasCompositions && c.id != compo.id && p.maxSelection == 1
          ? c.compositions.map((cmp) => (cmp.numberOfSelection = 0))
          : null
      );
      if (p.maxSelection == 1)
        p.components.map((c) => (c.numberOfSelection = 0));

      compo.compositions = compositions;
      compo.compositions.map((v) =>
        v.isDefault && !v.defaultHidden ? (v.numberOfSelection = 1) : null
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      compo.numberOfSelection > 0 ? null : (compo.numberOfSelection = 1);
      compo.compositionSelection = compositionQunatity;
      compo.compositionsTotalPrice = compositionsTotalPrice;
      props.updateCurrentPrice(
        compositionsTotalPrice +
          (compo.numberOfSelection == 1 ? compo.price : 0),
        "INCREASE"
      );
      p.components[index] = compo;
      p.numberOfSelection = 1;
      console.log("propsindexxxxxx", props.index, p);
      __makeChangeInCurrentCategory({ ...p }, props.index);
      setproduct({ ...p });
    } catch (error) {
      console.log("errorQSQ", error);
    }
  };

  return (
    <Dialog
      className="CompositionModal"
      fullWidth={true}
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      onBackdropClick={() => {
        setcompositions(props.obj?.components[props.componentId].compositions);
        setcompositionQunatity(component?.compositionSelection);
        setcompositionsTotalPrice(component?.compositionsTotalPrice);
        // setcompositions(props.obj?.components[props.componentId].compositions); // Reset compositions to initial state
        // Close the dialog
      }}
    >
      <DialogTitle className="TitleModal">Composition</DialogTitle>
      <img
        className="closeModal"
        onClick={handleClose}
        src={CloseIconBtn}
        alt=""
      />
     
      <DialogContent>
    
        {component.hasCompositions &&
          (component.maxCmpSelection === 1 ? (
            <div className="compositions-wrapper compositionsContainer">
              {component.compositions.map((item: any, i: number) => (
                <Composition
                  compositions={compositions}
                  maxSelection={item.maxSelection}
                  key={i}
                  control={<Checkbox />}
                  item={item}
                  totalCount={totalCount}
                  setTotalCount={setTotalCount}
                  i={i}
                  maxCmp={props.component}
                  indexOfComponent={product.components.indexOf(component)}
                  updateCurrentPriceTotal={props.updateCurrentPrice}
                  updateCompositionPrice={props.updateCompositionPrice}
                  updateProductHasCompositions={(
                    product: serviceMenuModels.Product
                  ) => setproduct(product)}
                  product={product}
                  updateProductSelection={updateProductSelection}
                  maxCmpSelectionState={maxCmpSelectionState}
                  setSingleCount={setSingleCount}
                  singleCount={singleCount}
                  compositionQunatity={compositionQunatity}
                />
              ))}
            </div>
          ) : (
            <Stack className="compositionsContainer" fontSize={"small"}>
              {component.compositions.map((item: any, i: number) =>
                item.maxSelection > 1 ? (
                  <QuantityCounter
                    maxSelection={item.maxSelection}
                    key={i}
                    item={item}
                    i={i}
                    totalCount={totalCount}
                    setTotalCount={setTotalCount}
                    indexOfComponent={product.components.indexOf(component)}
                    updateCurrentPriceTotal={props.updateCurrentPrice}
                    updateCurrentPrice={props.updateCompositionPrice}
                    updateProductHasCompositions={(
                      product: serviceMenuModels.Product
                    ) => setproduct(product)}
                    product={product}
                    updateProductSelection={updateProductSelection}
                    maxCmpSelectionState={maxCmpSelectionState}
                    setSingleCount={setSingleCount}
                    singleCount={singleCount}
                    compositionQunatity={compositionQunatity}
                  />
                ) : (
                  <Composition
                    compositions={compositions}
                    maxSelection={item.maxSelection}
                    key={i}
                    control={<Checkbox />}
                    item={item}
                    totalCount={totalCount}
                    setTotalCount={setTotalCount}
                    i={i}
                    maxCmp={props.component}
                    indexOfComponent={product.components.indexOf(component)}
                    updateCurrentPriceTotal={props.updateCurrentPrice}
                    updateCompositionPrice={props.updateCompositionPrice}
                    updateProductHasCompositions={(
                      product: serviceMenuModels.Product
                    ) => setproduct(product)}
                    product={product}
                    updateProductSelection={updateProductSelection}
                    maxCmpSelectionState={maxCmpSelectionState}
                    setSingleCount={setSingleCount}
                    singleCount={singleCount}
                    compositionQunatity={compositionQunatity}
                  />
                )
              )}
            </Stack>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            backgroundColor: "#EE8113",
            width: "100%",
            height: "40px",
            fontSize: "18px",
            color: "white",
            borderRadius: "12px",
            maxWidth: "282px",
            margin: "0 auto",
          }}
          onClick={() => {
            handleSubmit();
            handleClose();
            props.handleParagraphClick(props.component?.name);
          }}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => {
  return {
    selectedProductWithComposition: state.menu.selectedProductWithComposition,
    selectedCategory: state.menu.selectedCategory,
    menuId: state.menu.menuId,
    mode: state.menu.mode,
    menu: state.menu.menu,
    productHasCompositionId: state.menu.productHasCompositionId,
    componentId: state.menu.componentId,
    compositionsPrice: state.cart.compositionsPrice,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCurrentPrice: (newPrice: number, actionType: string) =>
      dispatch(updateCurrentPrice(newPrice, actionType)),
    updateCurrentCategory: (
      category: serviceMenuModels.Category,
      menuId: number
    ) => dispatch(updateCurrentCategory(category, menuId)),
    updateCompositionPrice: (newPrice: number, actionType: string) =>
      dispatch(updateCompositionsPrice(newPrice, actionType)),
    preSaveCart: (presavedCart: any) => dispatch(preSaveCart(presavedCart)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompositionDialog);
