import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { confirmPaymentActionSuccess, createPaymentActionSuccess } from "../actions/actions";
import { paymentApi } from "../../services/apiGate";

//* get all categories  

export function* createPaymentSaga(action: any) {
    try {
        let response: AxiosResponse = yield paymentApi.createPayment(action.order)


        if (response.status == 204) {

        }
        if (response.status == 200) {



            let data = response.data
            console.error('PYAMENT MESSAGE =>>>>>',data)
            yield put(createPaymentActionSuccess(data))
        }
        else {

        }
    } catch (error: any) {
       
       


    }
}
export function* confirmPaymentSaga(action: any) {
    try {
        let response: AxiosResponse = yield paymentApi.confirmPayment(action.order)

        if (response.data) {

            let data = response.data

            yield put(confirmPaymentActionSuccess(data))
        }
    } catch (error: any) {



    }
}
