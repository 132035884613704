import React, { useEffect, useState } from "react";
import NavBar from "../../components/Nbar/Nav-bar";
import Footer from "../../components/AppLayout/footer/Footer";
import "./paiement.scss";
import LeftArrow from "../../images/arrow-left_grey.svg";
import { Link, useNavigate } from "react-router-dom";
import DistanceIcon from "../../images/distanceIcon.svg";
import { GoogleMap, Marker } from "@react-google-maps/api";

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Navigate } from "react-router-dom";
import { StripeCardElement, loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { config } from "../../config";
import ProcessingCommand from "./ProcessingCommand/ProcessingCommand";
import InvoiceCommand from "./ProcessingCommand/InvoiceCommand";
import { languageData } from "../../i18n";
import CheckoutForm from "./CheckoutForm";
import { connect } from "react-redux";
import {
  clearStoreItem,
  getmyprofile,
  pushCategory,
} from "../../store/actions/actions";

const Paiement = (props: any) => {
  const [showMarker, setshowMarker] = useState(false);
  const [isOnlinePaymentChecked, setOnlinePaymentChecked] = useState(false);
  const [showProcessingCommand, setShowProcessingCommand] = useState(false);
  const [objectcategory, setObjectCategory] = useState(
    localStorage.getItem("resturantId")
  );
  const [rerender, setRerender] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>(null); // Assuming you want to store restaurant data
  const stripePromise = loadStripe(config.stripe_public_key);
  const [promptCommand, setpromptCommand] = useState(false);
  const [command, setcommand] = useState();
  const [qrcode, setqrcode] = useState("");

  const navigate = useNavigate();

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOnlinePaymentChecked(event.target.value === "online");
  };
  useEffect(() => {
    setShowProcessingCommand(false);
  }, []);

  useEffect(() => {
    console.log("restaurantData", restaurantData, objectcategory);
    if (
      restaurantData === undefined ||
      restaurantData?.length === 0 ||
      objectcategory === null
    ) {
      navigate("/");
    }
    console.log("payment page");
  }, []);
  useEffect(() => {
    // Fetch data based on objectcategory
    if (objectcategory !== null && props.savedCategories) {
      // Fetch restaurant data or whatever data you need
      const data = props.savedCategories[objectcategory];
      setRestaurantData(data);
      if (!data?.restaurant?.isOnSpotPaymentActive) {
        setOnlinePaymentChecked(true);
      }
    }
  }, [objectcategory, props.savedCategories, rerender]);

  useEffect(() => {
    setRerender((prev) => !prev);
  }, [objectcategory]);

  const submitCommand = () => {
    window.scrollTo(0, 0);
    // document.querySelector("body")?.classList.add("disable-scroll");
    document.querySelector(".paimentSection")?.classList.add("slideUpHide");
    document
      .querySelector(".ProcessingCommandComponent")
      ?.classList.add("slideUpShow");
    setShowProcessingCommand(true);
    setTimeout(() => {
      ShowInvoice();
    }, 4500);
  };

  const ShowInvoice = () => {
    document
      .querySelector(".InvoiceCommandComponent")
      ?.classList.add("DisplayInvoice");
  };

  useEffect(() => {
    const data = props.getmyprofile();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("redirectedToAuth")) {
      localStorage.setItem("redirectedToAuth", "false");
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setshowMarker(true);
    }, 2000);
  }, []);
  const onLoad = (marker: any) => {
    console.log("marker: ", marker);
  };
  const center = {
    lat: props.location?.lat,
    lng: props.location?.lng,
  };

  const style = {
    height: "10vh",
    width: "100%",
  };
  const clearRestauId = () => {
    let obj = props.savedCategories;
    delete obj[objectcategory as string];

    props.pushCategory(obj);
  };
  console.log("restaurantData?.restaurant", restaurantData?.restaurant);
  return (
    <div className="PaiementPage">
      <section>
        <NavBar />
        <div className="body-wrapper">
          <div className="TopPageHeader">
            <div onClick={() => navigate(-1)}>
              <div>
                <img src={LeftArrow} alt="" />
                <p>Paiement</p>
              </div>
            </div>
          </div>
          <main className="paimentSection">
            <div className="body-paiment">
              <div className="body-wrapper">
                <div className="map-location">
                  <div className="restaurant-name">
                    <p>
                      {objectcategory !== null
                        ? restaurantData?.restaurant?.nom
                        : ""}
                    </p>
                  </div>
                  <div className="location-adresse">
                    <p>
                      {objectcategory !== null
                        ? restaurantData?.restaurant?.address
                        : ""}
                    </p>
                  </div>
                  <div className="map-convas">
                    <div className="map">
                      <GoogleMap
                        options={{
                          disableDefaultUI: true, // Disable default UI controls
                          draggable: false, // Disable dragging
                          clickableIcons: false, // Disable clickable icons
                        }}
                        mapContainerStyle={style}
                        zoom={15}
                        center={center}
                      >
                        {showMarker && (
                          <Marker opacity={1} options={{}} position={center} />
                        )}
                      </GoogleMap>
                    </div>
                    <div className="location-details">
                      <div className="distance">
                        <img src={DistanceIcon} alt="" />
                        <div>
                          <p>Distance</p>
                          <p>0,2 Km</p>
                        </div>
                      </div>
                      <div className="delivery-type">
                        <p className="label">Maintenant</p>
                        <p>
                          <b>
                            {objectcategory !== null
                              ? restaurantData?.mode == 0
                                ? "À Emporter"
                                : "Sur place"
                              : null}
                            , prête dans :{" "}
                            {objectcategory !== null
                              ? restaurantData?.restaurant?.tempsPrep
                              : ""}{" "}
                            min
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`payment-methode  show-card `}>
                  <p className="block-title">Méthode de paiement</p>
                  <div className="payments-wrapper">
                    {restaurantData?.restaurant?.isOnSpotPaymentActive && (
                      <div className="onsite-payment">
                        <input
                          type="radio"
                          id="onsite"
                          name="payment-methode"
                          value="onsite"
                          checked={!isOnlinePaymentChecked}
                          onChange={handlePaymentMethodChange}
                        />
                        <label htmlFor="onsite">Sur place</label>
                      </div>
                    )}
                    {restaurantData?.restaurant?.isStripeActive && (
                      <div className="online-payment">
                        <input
                          type="radio"
                          id="online"
                          name="payment-methode"
                          value="online"
                          checked={isOnlinePaymentChecked}
                          onChange={handlePaymentMethodChange}
                        />
                        <label htmlFor="online">En ligne</label>
                      </div>
                    )}
                  </div>
                  <div className="payment-info">
                    <div className="sous-total">
                      <p className="label">Sous-total</p>
                      <p className="value">
                        {objectcategory !== null &&
                        restaurantData?.categories?.length > 0
                          ? restaurantData.categories
                              .reduce((total: number, category: any) => {
                                return (
                                  total + category.amount * category.quantity
                                );
                              }, 0)
                              .toFixed(2)
                          : "15"}
                        €
                      </p>
                    </div>
                    <div className="frais">
                      <p className="label">Frais</p>
                      <p className="value">0,00€</p>
                    </div>
                    <div className="total">
                      <p className="total-label">Total</p>
                      <p className="total-value">
                        {" "}
                        {objectcategory !== null &&
                        restaurantData?.categories?.length > 0
                          ? restaurantData.categories
                              .reduce((total: number, category: any) => {
                                return (
                                  total + category.amount * category.quantity
                                );
                              }, 0)
                              .toFixed(2)
                          : "15"}
                        €
                      </p>
                    </div>
                  </div>
                  <div className="card-section">
                    <Elements
                      stripe={stripePromise}
                      options={{
                        mode: "payment",
                        amount: 350,
                        currency: "eur",
                      }}
                    >
                      <CheckoutForm
                        setqrcode={(str: string) => setqrcode(str)}
                        setcommand={(command: any) => setcommand(command)}
                        setpromptCommand={(value: boolean) =>
                          setpromptCommand(value)
                        }
                        isOnlinePaymentChecked={isOnlinePaymentChecked}
                        setShowProcessingCommand={() =>
                          setShowProcessingCommand(true)
                        }
                      ></CheckoutForm>
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {showProcessingCommand && (
            <div className="ProcessingCommandComponent">
              <ProcessingCommand
                resturant={restaurantData?.restaurant?.nom}
                distance={restaurantData?.restaurant?.address}
                tempsPrep={restaurantData?.restaurant?.tempsPrep}
                placement={
                  restaurantData?.mode == 0 ? "À Emporter" : "Sur place"
                }
              />
            </div>
          )}
          {promptCommand && (
            <div className="InvoiceCommandComponent ">
              <InvoiceCommand
                clearItemStore={clearRestauId}
                command={command}
                qrcode={qrcode}
              />
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    savedCategories: state.cart.savedCategories,
    location: state.auth.location,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getmyprofile: () => dispatch(getmyprofile()),
    pushCategory: (category: serviceMenuModels.Category) =>
      dispatch(pushCategory(category)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Paiement);
