import "./style.scss";
import srcLogo from "../../../images/fioteat-logoo.png";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import withNavigateHook from "../../WithNavigation";
import { logout, updateUserLocation } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { Logout, Settings } from "@mui/icons-material";
import ClippedDrawer from "../../Cart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SideNav from "../sideNav";
import MenuIcon from "@mui/icons-material/Menu";
import localStorage from "redux-persist/es/storage";
import pizza01 from "./pizza02.jpg";
import logoBlack from "../../../images/logo-fioEat-black.svg";
import Panier from "../../Nbar/Panier";
import Profile from "../../Nbar/profile";

// import FioEat from "../../images/fioteat-logoo.png";

function NavBar(props: any) {
  const location = useLocation();
  const [isSimple, setIsSimple] = useState<boolean>(false);
  const [isRestaurantView, setIsRestaurantView] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [autoComplete, setAutoComplete] = useState<any>();
  const [addressText, setAddressText] = useState<string>("");
  const [hasListener, setHasListener] = useState<boolean>(false);
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false);
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(false);
  const [loggedUser, setloggedUser] = useState(false);
  const navigate = useNavigate();
  const [userWantToLogout, setuserWantToLogout] = useState(0);
  const fetchUserSigning = async () => {
    const accessToken = await localStorage.getItem("accessToken");
    setloggedUser(accessToken != null);
  };
  useEffect(() => {
    fetchUserSigning();
  }, [userWantToLogout]);

  const UserLogIn =
    props.sendVerificationCodeResponse !== null &&
    props.sendVerificationCodeResponse !== undefined;

  const [refreshed, setRefreshed] = useState(false);

  if (UserLogIn) {
    localStorage.setItem("Token", props.sendVerificationCodeResponse?.token);
  } else {
    localStorage.removeItem("Token");
  }

  const openCart = () => {
    if (props.savedCategories && Object.values(props.savedCategories)[0])
      setCartOpen(true);
  };

  let place: any;

  // const handleScroll = (event: any) => {
  //   const currentScrollPos = window.scrollY;

  //   // Check if scrolling up and at the top of the page
  //   if (currentScrollPos === 0 && event.deltaY < 0) {
  //     setIsExpanded(true);
  //   } else {
  //     setIsExpanded(false);
  //   }
  // };

  useEffect(() => {
    // Logic to determine the navbar state based on the current location
    if (
      location.pathname.includes("/Authentification") ||
      location.pathname.includes("/payment")
    ) {
      setIsSimple(true);
      setIsExpanded(false);
      setIsRestaurantView(false);
    } else {
      if (location.pathname.includes("/restaurant")) {
        setIsSimple(false);
        setIsExpanded(false);
        setIsRestaurantView(true);
      } else {
        setIsSimple(false);
        setIsExpanded(true);
        setIsRestaurantView(false);
      }
    }

    //logic to manage the scroll events for the expanded nav
    if (location.pathname === "/" && !hasListener) {
      // Attach the scroll event listener
      // window.addEventListener("wheel", handleScroll);
      // setHasListener(true);
    } else if (hasListener) {
      // Remove the scroll event listener if it was previously added
      // window.removeEventListener("wheel", handleScroll);
      // setHasListener(false);
    }
    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      // window.removeEventListener("wheel", handleScroll);
    };
  }, [location.pathname]);

  /* const logoutUser = () => {
    localStorage.removeItem("accessToken");
  
    if (token !== null) { // Check if token is not null before removing it
      localStorage.removeItem("token");
    }
  
    props.logout();
    props.navigate("/");
  };
   */

  //Search locatio Autocomplete states
  const onAutoCompleteLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };
  const isLargeScreen = useMediaQuery("(min-width: 601px)");
  const onPlaceChanged = () => {
    if (!autoComplete) return;

    place = autoComplete.getPlace();

    let obj = {
      lat: place?.geometry?.location.lat(),
      lng: place?.geometry?.location.lng(),
    };

    props.updateUserLocation({
      ...obj,
      shownAddress: place?.formatted_address,
    });

    setAddressText(place?.formatted_address);

    if (props.location.pathname !== "/restaurants")
      props.navigate("/restaurants");
  };

  //logic for the user dropdown menu (profile , lg out , setting )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClicke = (id: any) => {
    navigate(`/restaurant/${id}`);
  };

  return (
    <div className="navbar  column full-width">
      <div className="navContainer full-size">
        <div className="navbarCompactGrp  all-center row">
          <div
            className="logoContainer full-height all-center"
            style={{
              justifyContent: "flex-start",
              width: isSimple ? "" : "215px",
            }}
          >
            <Link to="/" onClick={(e) => e.stopPropagation()}>
              <img
                src={logoBlack}
                alt=""
                onClick={() => {
                  props.navigate("/");
                }}
              />
            </Link>
          </div>

          <div
            className="signContainer full-height all-center row"
            style={{
              width: isSimple ? "0" : "",
              justifyContent: "flex-end",
            }}
          >

            {!loggedUser ? (
              <IconButton
                onClick={() => {
                  // openCart();
                  props.navigate("/Authentification");
                  handleClose();
                }}
              >
                <button className="btn-style dark-btn">Se connecter</button>
              </IconButton>
            ) : (
              ""
            )}


            {loggedUser ? (
              <Profile setLogoutRetries={setuserWantToLogout} />
            ) : (
              ""
            )}
            <ClippedDrawer
              open={cartOpen}
              setOpen={(bool: boolean) => setCartOpen(bool)}
            />

            {true && (
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open ? open : false}
                onClose={handleClose}
                onClick={handleClose}
                sx={{ width: "350px" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >

                <MenuItem
                  onClick={(e) => {
                    props.navigate("/Authentification");
                    handleClose();
                  }}
                >
                  <Avatar /> Profile
                </MenuItem>
                <Divider />
              </Menu>
            )}
            <Panier></Panier>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    savedCategories: state.cart.savedCategories,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
    updateUserLocation: (location: any) =>
      dispatch(updateUserLocation(location)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(NavBar));
