import React, { useState } from "react";
import TestImg from "../../images/restuarntNameExample.png";
import { Commande } from "../../models/services/module.order";
import { config } from "../../config";
import moment from "moment";
import SkeletonElement from "../skeleton/SkeletonElement";
export default function CommandElement({
  order,
  selectOrder,
}: {
  order: Commande;
  selectOrder: (order: Commande) => void;
}) {
  const ShowCommandDetails = () => {
    selectOrder(order);
    document

      .querySelector(".commands-wrapper")

      ?.classList.add("ShowCommandDetails");
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="CommandElement" onClick={ShowCommandDetails}>
      <div className="CommandImg">
        <img
          src={config.api_baseUrl_Picture + "/" + order.imageRestaurant}
          alt=""
          onLoad={() => setImageLoaded(true)}
        />
      </div>

      <div className="CommandInfo">
        <p>
          <b className="restaurant-name">{order.nomRestaurant}</b>
        </p>
        <p>
          <b className="order-number">Order N° #{order.referenceCommande}</b>
        </p>
        <p className="date-order">
          {moment(order.date).format("DD MMM à HH:mm")}{" "}
          <span className="delivery-type">
            {order.diningMode == 0 ? "à emporter" : "sur Place"}
          </span>
        </p>
        <p className="order-Price">
          <span className="order-count">{order.nbArticles} article(s)</span>
          <span className="price">• {order.totalPrix}€</span>
        </p>
      </div>
     
    </div>
  );
}
